.wrapperSeccion1
{
    display: flex;
    justify-content: center;
    background: #2D2D2D 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 220px;
    margin-top: 50px;

    .contenedorSn1
    { 
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 80%;
        align-items: center;
        justify-content: center;
        

        .Itemtxt
        {
            flex: 1;
            display: flex;
            justify-content: center;
            width: 100%;

            .img
            {
                padding-top:15px;
                padding-right: 3.5%;
                color: #4198B4;
                align-items: center;

                .pay{
                    width: 79px;
                    height: 77px;   
                }
                .cet{
                    width: 66px;
                    height: 83px;
                }
                .lap{
                    width: 79px;
                    height: 56px;
                    padding-top:5px;
                }

            }

            .infoItem 
            {
                width: 305px;

                .tit
                {
                    font: normal normal bold 21px/25px Helvetica;
                    letter-spacing: 1.10px;
                    color: #FFFFFF;
                    opacity: 1;
                    display: flex;
                        
                }

                span{
                    text-align: left;
                    font: normal normal normal 18px/30px Raleway;
                    letter-spacing: 0.07px;
                    color: #FFFFFF;
                    opacity: 1;

                }
            }
        }

           
    }

    @media only screen and (min-width: 900px) and (max-width: 1150px) {
            height: 100%;
            .contenedorSn1{
                transition: 1s;
                width: 90%;
                margin:10px 0px 10px 0px;
                

                .Itemtxt{
                    width: 100%;
                    gap: 15px;
                    margin-bottom: 10px;
                    
                    .infoItem {
                        width: 200px;
                        .tit
                        {
                            font: normal normal bold 20px Helvetica;
                                
                        }
        
                        span{
                            font: normal normal normal 16px Raleway;
        
                        }
                    }

                    .img
                    {
                        .pay{
                            width: 59px;
                            height: 57px;   
                        }
                        .cet{
                            width: 46px;
                            height: 63px;
                        }
                        .lap{
                            width: 59px;
                            height: 36px;
                            padding-top:5px;
                        }

                    }
                    
                }            
            }
    }
    @media only screen and (min-width: 380px) and (max-width: 900px) {
        height: 100%;
        .contenedorSn1{
            transition: 1s;
            width: 90%;
            display: inline;
            margin:10px 0px 10px 0px;
            

            .Itemtxt{
                width: 100%;
                gap: 15px;
                margin-bottom: 10px;
                
                .infoItem {
                    width: 100%;
                    .tit
                    {
                        font: normal normal bold 20px Helvetica;
                            
                    }
    
                    span{
                        font: normal normal normal 16px Raleway;
    
                    }
                }

                .img
                {
                    .pay{
                        width: 59px;
                        height: 57px;   
                    }
                    .cet{
                        width: 46px;
                        height: 63px;
                    }
                    .lap{
                        width: 59px;
                        height: 36px;
                        padding-top:5px;
                    }

                }
                
            }            
        }
        
    }
    @media only screen and (max-width: 380px) {
        height: 100%;
            .contenedorSn1{
                transition: 1s;
                width: 90%;
                display: inline;
                margin:10px 0px 10px 0px;
                

                .Itemtxt{
                    width: 100%;
                    gap: 15px;
                    margin-bottom: 10px;
                    
                    .infoItem {
                        width: 100%;
                        .tit
                        {
                            font: normal normal bold 20px Helvetica;
                                
                        }
        
                        span{
                            font: normal normal normal 16px Raleway;
        
                        }
                    }

                    .img
                    {
                        .pay{
                            width: 59px;
                            height: 57px;   
                        }
                        .cet{
                            width: 46px;
                            height: 63px;
                        }
                        .lap{
                            width: 59px;
                            height: 36px;
                            padding-top:5px;
                        }

                    }
                    
                }            
            }
    }
}