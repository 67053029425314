.wrapperDirecciones{
    width: 100%;
    margin-top: 30px;

    .tituloDirecciones{
        text-align: left;
        font: normal normal bold 24px/29px Helvetica;
        letter-spacing: 0.5px;
        color: #4198B4;
        margin:20px;
    }

    .ContentDirecciones{
        width: 100%;
        display: grid;
        gap:30px;
        margin-top: 30px;
        .Link{text-decoration: none;}

        .AccountDirecciones{
            gap:20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: center;
            margin:20px;

            .cardAddressPerfil{
                height: 227px;
                border: 1px solid #A7A7A7;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 3px 6px 6px #00000029;
                opacity: 1;
                color:  var(--btn-color);
                display: flex;
                position : relative;
                
                .dir{
                    width: 100%;
                    height: 100%;

                    .itemsDir{
                        margin-top: 40px;
                    .item{
                        margin-bottom: 5px;
                        padding-left: 10px;
                        text-align: left;
                        font: normal normal normal 16px Raleway;
                        letter-spacing: 0px;
                        color:  var(--btn-color);
                        }
                        .title{
                            margin-bottom: 15px;
                            font-size: 18px;
                            font-weight: bold;
                        }
                    }
                    .Itempredeterminado{
                        position: absolute;
                        top:0px;
                        background: #4198B4 0% 0% no-repeat padding-box;
                        width: 100%;
                        height: 31px;
                        font: normal normal bold 13px Helvetica;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        .item{
                            margin-left: 10px;
                        }
                    }

                    .acciones
                    {
                        display: flex;
                        justify-content: space-between;
                        height:30%;
                        align-items: flex-end;
                        margin: 10px;
                        .itemAccion{
                            font: normal normal bold 14px/17px Helvetica;
                            letter-spacing: 0.44px;
                        }
                        .delete{
                            color: #B44141;
                        }
                    }
                }
            }
        }

        .ContentBtn{
            margin-left: 20px;
            .add{
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 248px;
                height: 30px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
                letter-spacing: 0px;
                color: #FFFFFF;
            }
        }
    }
    .ContentAddAddress{
        width: 100%;
        display: grid;

        .AddAddress{
            justify-content: center;
            display: grid;
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        
        .ContentDirecciones{
            width: 100%;
            display: grid;
            gap:30px;
            margin-top: 30px;
            .Link{text-decoration: none;}
    
            .AccountDirecciones{
                gap:20px;
                display: table-column;
                margin-bottom: 30px;
                justify-content: center;
                
                .cardAddressPerfil{
                    margin: 0px 10px 20px 10px;
                }
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                margin: 0px;
                .add{
                    width: 200px;

                }
            }
        }
        .ContentAddAddress{
            width: 100%;
            display: inline;
    
            .AddAddress{
                justify-content: center;
                display: flex;
            }
        }
        
    }
    @media only screen and (max-width: 320px) {
        .ContentDirecciones{
            width: 100%;
            display: grid;
            gap:30px;
            margin-top: 30px;
            .Link{text-decoration: none;}
    
            .AccountDirecciones{
                gap:20px;
                display: table-column;
                margin-bottom: 30px;
                justify-content: center;
                
                .cardAddressPerfil{
                    margin: 0px 10px 20px 10px;
                }

            }

            .ContentBtn{
                display: flex;
                justify-content: center;
                margin: 0px;
                .add{
                    width: 200px;

                }
            }
        }
        .ContentAddAddress{
            width: 100%;
            display: inline;
    
            .AddAddress{
                justify-content: center;
                display: flex;
            }
        }
    }
}

