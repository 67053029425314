.ContentInfo{
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 30px;


    .PerfilInfo
    {
        width: 70%;
        display: grid;
        gap: 30px;

        .Link{text-decoration: none;}

        .tituloInfo{
            text-align: left;
            font: normal normal bold 24px/29px Helvetica;
            letter-spacing: 0.5px;
            color: #4198B4;
        }

        .form{
            
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:10px;

            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap:10px;
                width: 100%;
                
                .FormRow{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    
                    input{
                        width: 100%;
                        height: 50px;
                        font-size: 18px;
                        border: 1px solid #A7A7A7;
                    }
                    label{
                        margin-bottom: 5px;
                    }
                }
            }
            button{
                color:white;
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 298px;
                height: 60px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
            }

            .mejerror{
                p {color: brown;}
            }
            .msjeOK{
                p {color: rgb(0, 146, 149);}
            }

            .Actions{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                gap:10px;

                .buttonAction{
                    height: 60px;

                }
                .OK{
                    color: var(--btn-color);
                    font-size: 50px;
                    cursor: pointer;
                }
                .RET{
                    color: var(--btn-color);
                    font-size: 50px;
                    cursor: pointer;
                }
            }
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        
        .PerfilInfo
        {
            width: 100%;
            display: grid;
            gap: 30px;
            margin: 15px;

            .form{
            
                display: flex;
                align-items: center;
                gap:10px;
                width: 100%;
                
                
                .row {
                    display: grid;
                    flex-direction: column;
                    gap:10px;
                    justify-content: initial;
                    
                    .FormRow{
                        display: grid;
                        flex-direction: column;
                        width: 100%;
                        
                        input{
                            width: auto;
                            height: 50px;
                            font-size: 18px;
                            border: 1px solid #A7A7A7;
                        }
                        label{
                            margin-bottom: 5px;
                        }
                    }
                }
            }
    
        }
        
    }
    @media only screen and (max-width: 320px) {
        .PerfilInfo
        {
            width: 100%;
            display: grid;
            gap: 30px;
            margin: 15px;

            .form{
            
                display: flex;
                align-items: center;
                gap:10px;
                width: 100%;

                button{
                    width: 198px;
                }
                
                
                .row {
                    display: grid;
                    flex-direction: column;
                    gap:10px;
                    
                    .FormRow{
                        display: grid;
                        flex-direction: column;
                        width: 100%;
                        
                        input{
                            width: auto;
                            height: 50px;
                            font-size: 18px;
                            border: 1px solid #A7A7A7;
                        }
                        label{
                            margin-bottom: 5px;
                        }
                    }
                }
            }
    
        }
    }
}