.wrapperTarjeta{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 24px 54px #0000000D;
    background: #F7F7F7;
    margin-bottom:50px;
    padding:30px;   
    width: 600px;

    .titulo{
        font: normal normal bold 40px/39px Helvetica;
        letter-spacing: 0px;
        color: #1D4C5A;
        text-align: center;
    }
    
    .form-container {
        display: flex;
        gap:20px;
        width: 100%;
        
        .DataTarjeta{
            width: 100%;
            align-items: center;
            letter-spacing: 0.57px;
            color: #AAAAAA;

            .col2{
                display: grid;
                grid-template-columns: auto auto;
                gap:40px;
            }
        
            .row{
                gap:10px;
                width: 100%;

                h2 {
                    padding-top: 0px;
                    text-align: center;
                }

                .itemform
                {
                    width: 100%;
                    display: grid;
                    
                    .hidden{
                        display: none;
                    }

                    .InvalidEmail{
                        font-size: 12;
                        color: #aa0b20;
                        margin: 0;
                    }
                }
            
            }
            .rowButton
            {
                text-align: right;
                padding-bottom: 30px;
            }
        
            .form-title {
                font: normal normal bold 18px/25px Helvetica;
                letter-spacing: 0.5px;
                color: #000000;
                opacity: 1;
                margin-bottom: 25px;
            }
            
            .form-input {
                height: 40px;
                margin-right: 10px;
                margin-bottom: 1rem;
                border: 1px solid #A7A7A7;
                opacity: 1;
                font-size: 20px;
                width: 100%;
            }

            .invalid{
                border-color: #aa0b20;
                background-color: #f5dbdb;
            }
        
            .form-textarea {
                width: 100%;
            
                margin-bottom: 1rem;
                border: 1px solid #A7A7A7;
                opacity: 1;
                height: 10rem;
            }
        
            .form-submit-button {
                color:white;
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 298px;
                height: 46px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
                margin-top:30px;
            }
        }
    }
}

@media only screen and (min-width: 900px) and (max-width: 1100px){
}
@media only screen and (min-width: 320px) and (max-width: 900px) {
    
    .wrapperTarjeta{
    
        .titulo{
            font: normal normal bold 28px Helvetica;
        }
    }
}
@media only screen and (max-width: 320px) {
}