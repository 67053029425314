.WrapperCheckOut{
    justify-content: center;
    display: flex;
    margin-bottom:80px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    opacity: 1;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    .contentCheckOut{
        width: 90%; 
        max-width: 1620px;
        height: 100%;

        .rutaCheckOut{
            font: normal normal medium 18px/21px Raleway;
            letter-spacing: 0px;
            color: #CDCFD1;
            opacity: 1;
            display: flex;
            
            img{
                width: 54px;
                height: 47px;
            }
            .t2
            {
                font: normal normal medium 18px/21px Raleway;
                letter-spacing: 0px;
                color: #4198B4;
                opacity: 1;
            }
        }
        .titulo{
            font: normal normal bold 38px/46px Helvetica;
            letter-spacing: 0px;
            color: #232323;
        }
        .rutaPago{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font: normal normal normal 18px/25px Helvetica;
            letter-spacing: 0px;
            color: #787a7a;
            align-items: center;

            .Active {
                color: #4198B4;
            }

            .itemRutaPago{
                display: flex;
                align-items: center;
                gap:10px;
                
                .circle {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .circle span {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }

                .circleActive {
                    background-color: #1D4C5A;
                    color: white;
                }


            }
        }

        .ContentInfoCkeck{
            display: flex;
            justify-content: space-between;
            gap:20px;
            
            .left{
                width: 70%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 10px 24px 54px #0000000D;
                opacity: 1;
                padding: 30px;
                padding-top:20px;                
                
                .title{
                    justify-content: center;
                    font: normal normal bold 18px/22px Helvetica;
                    letter-spacing: 0px;
                    color: #232323;

                    hr{
                        height: 1px;
                        background-color: #5797B1; 
                    }    
                    
                }

                .DatosSpei{
                    display: grid;
                    margin-top: 20px;
                    color:#232323;

                    .TitleSpei{
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .instruccionesSpei
                    {
                        display: grid;
                        gap: 20px;
                        text-align: justify;
                        margin-bottom: 20px;

                        .punto{
                            font-weight: 500;
                        }
                        .PasoData{
                            
                            .cuadroData{
                                .item{
                                    list-style-type: disc;
                                }
                            }
                        }
                    }

                }

                

                .logos {
                    display: flex;
                    .logoTC{
                        img{
                        width: 210px;
                        height: 68px;
                        }
                    }
                    .logoSpei{
                    img{
                        width: 200px;
                        height: 68px;
                        }
                    }
                }

                .txtFacturacion
                {
                    font: normal normal normal 15px Raleway;
                    color: #232323;
                    padding:3px;
                }
                .FormaDePago{
                    display: flex;
                    grid-template-columns: repeat(1, 1fr);
                    grid-column-gap:10px;
                    width: 100%;
                    height: auto; 
                    margin-top: 20px;
                    
                        .btn{
                            border: none;
                            text-decoration: none;
                            cursor: pointer;    
                            height: 150px;
                            width: 100%; 
                            
                            .btnContent{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: white;
                                color: var(--btn-color);
                                border: 1px solid var(--btn-color);
                                height: 100%;

                                .logoTC{
                                    img{
                                        width: 210px;
                                        height: 68px;
                                    }
                                }

                                .logoSpei{
                                    img{
                                        width: 210px;
                                        height: 68px;
                                    }
                                }

                                .txt{
                                    font: normal normal bold 18px Raleway;
                                    letter-spacing: 0px;
                                }
                                
                                .imgn{
                                    color: var(--btn-color);
                                    width: 54px;
                                    height: 47px;
                                }

                                .Active{
                                    color: white;
                                }
                            }
                            .Active{
                                background-color: #529db4;
                                color:white;
                            }
                        }
                }
                .titlenewTC{
                    font: normal normal 500 17px Helvetica;
                    letter-spacing: 0px;
                    color: #232323;
                    margin: 15px 0px 15px 0px;
                }
                .form-container {
                    display: flex;
                    gap:20px;
                    width: 100%;
                    
                    .DataTarjeta{
                        width: 100%;
                        align-items: center;
                        letter-spacing: 0.57px;
                        color: #AAAAAA;
    
                        .col2{
                            display: grid;
                            grid-template-columns: auto auto;
                            gap:40px;
                        }
                       
                        .row{
                            gap:10px;
                            width: 100%;
    
                            h2 {
                                padding-top: 0px;
                                text-align: center;
                            }
    
                            .itemform
                            {
                                width: 100%;
                                display: grid;
                                
                                .hidden{
                                    display: none;
                                }
    
                                .InvalidEmail{
                                    font-size: 12;
                                    color: #aa0b20;
                                    margin: 0;
                                }
                            }
                        
                        }
                        .rowButton
                        {
                            text-align: right;
                            padding-bottom: 30px;
                        }
                    
                        .form-title {
                            font: normal normal bold 18px/25px Helvetica;
                            letter-spacing: 0.5px;
                            color: #000000;
                            opacity: 1;
                            margin-bottom: 25px;
                        }
                        
                        .form-input {
                            height: 40px;
                            margin-right: 10px;
                            margin-bottom: 1rem;
                            border: 1px solid #A7A7A7;
                            opacity: 1;
                            font-size: 20px;
                            width: 100%;
                        }
    
                        .invalid{
                            border-color: #aa0b20;
                            background-color: #f5dbdb;
                        }
                    
                        .form-textarea {
                            width: 100%;
                           
                            margin-bottom: 1rem;
                            border: 1px solid #A7A7A7;
                            opacity: 1;
                            height: 10rem;
                        }
                    
                        .form-submit-button {
                            background-color: var(--btn-color);
                            color: #fff;
                            padding: 0.75rem 2rem;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                            text-align: left;
    
                            &:hover{
                                background-color: var(--btn-color-hover);
                            }
                        }
                    }
    
                    .MetodoPago{
                        width: 50%;
                        align-items: center;
                        letter-spacing: 0.57px;
                        color: #AAAAAA;
    
                        .col2{
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-column-gap:10px;
                        }
    
                        .BtnCompra{
                            margin-top: 100px;
    
                            .btn{
                                border: none;
                                width: 200px;
                                text-decoration: none;
                                cursor: pointer;    
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: var(--btn-color);
                                padding: 5px 5px;
                                color: white;  
                                float: right;          
    
                                    .txt{
                                        font-size: 15px;
                                    }
                                    
                            }
                        }
    
                        .FormaDePago{
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-column-gap:10px;
                            margin-bottom: 10px;
                            
                                .btn{
                                    border: none;
                                    text-decoration: none;
                                    cursor: pointer;       
                                    
                                    
                                    .btnContent{
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        background-color: white;
                                        padding: 20px 20px;
                                        color: var(--btn-color);
                                        border: 1px solid var(--btn-color);
    
                                        .txt{
                                            font-size: 20px;
                                        }
                                        
                                        .imgn{
                                            color: var(--btn-color);
                                            width: 54px;
                                            height: 47px;
                                        }
    
                                        .Active{
                                            color: white;
                                        }
                                    }
                                    .Active{
                                        background-color: var(--btn-color);
                                        color:white;
                                    }
                                }
                        }
    
                        .row{
                            gap:10px;
    
                            h2 {
                                padding-top: 0px;
                                text-align: center;
                            }
    
                            .itemform
                            {
                                width: 100%;
                                display: grid;
                                
                                .hidden{
                                    display: none;
                                }
                            }
                        
                        }
                        .rowButton
                        {
                            text-align: right;
                            padding-bottom: 30px;
                        }
                    
                        .form-title {
                            font: normal normal bold 18px/25px Helvetica;
                            letter-spacing: 0.5px;
                            color: #000000;
                            opacity: 1;
                            margin-bottom: 25px;
                        }
                        
                        .form-input {
                            padding: 0.8rem;
                            margin-bottom: 1rem;
                            border: 1px solid #A7A7A7;
                            opacity: 1;
                            font-size: 20px;
                        }
    
                        .invalid{
                            border-color: #aa0b20;
                            background-color: #f5dbdb;
                        }
                    
                        .form-textarea {
                            width: 100%;
                            padding: 0.5rem;
                            margin-bottom: 1rem;
                            border: 1px solid #A7A7A7;
                            opacity: 1;
                            height: 10rem;
                        }
                    
                        .form-submit-button {
                            background-color: var(--btn-color);
                            color: #fff;
                            padding: 0.75rem 2rem;
                            border: none;
                            border-radius: 5px;
                            cursor: pointer;
                            text-align: left;
    
                            
                        }
                    }
                }

                .CheckTyC{
                    display: grid;
                    gap: 10px;
                    align-items: center;
                    .Check{
                        display: flex;
                        font: normal normal normal 17px Raleway;
                        color: #000000;
                        .CheckS{
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                        }
                    }
                }
                
                .direcciones{
                    display: grid;
                    margin-top: 20px;
                    .cardAddress{

                        gap:10px;
                        margin-top:20px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        opacity: 1;
                        margin-bottom:10px;
                        font: normal normal normal 16px/19px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;
                        padding-left: 10px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        height: 86px;


                        .iconCard{
                            margin-right: 5px;
                            color:  var(--btn-color);
                        }
                        .dir{
                            gap:50px;
                            .item{
                                margin-top:5px;
                                font: normal normal normal 16px/19px Helvetica;
                                letter-spacing: 2px;
                                color: #232323;
                            }
                        }
                        
                    }
                    .cardAddress.selected{
                        border-left: 15px solid #4198b4;
                        background: #F2F2F2 0% 0% no-repeat padding-box;
                        border-left: 15px solid #4198b4;
                        color:  var(--btn-color);
                        opacity: 1;
                        font-size: 15px;
                        
                    }

                    .AddAddress{
                        display: flex;
                        justify-content: right;
                        margin-bottom:20px;

                        .txtlink{
                            color:var(--btn-color);
                            text-decoration: underline;
                            font: normal normal bold 16px Helvetica;
                            letter-spacing: 0px;
                            }
                    }
                }

                .MsjeError
                {
                    color: #660003;
                    font-size: 15px;
                }

                .SeleccionMobile{display: none;}
                .SeleccionMetodoMobile{display: none;}
            
            }
            .right
            {
                width: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 10px 24px 54px #0000000D;
                opacity: 1;
                padding: 30px;
                padding-top:20px;                
                max-height: 100%;
               
                gap:10px;

                .title{
                    justify-content: center;
                    font: normal normal bold 18px/22px Helvetica;
                    letter-spacing: 0px;
                    color: #232323;  
                    hr{
                        height: 1px;
                        background-color: #5797B1; 
                    }     
                }

                .direcciones{
                    
                    .cardAddress{

                        gap:10px;
                        margin-top:20px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        opacity: 1;
                        margin-bottom:10px;
                        font: normal normal normal 16px/19px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;
                        padding-left: 10px;
                        display: flex;
                        align-items: center;
                        height: 86px;


                        .iconCard{
                            margin-right: 5px;
                            color:  var(--btn-color);
                        }
                        .dir{
                            gap:50px;
                            .item{
                                margin-top:5px;

                                h1{font: normal normal bold 18px/21px Raleway;}
                            }
                        }
                        
                    }
                    .cardAddress.selected{
                        border-left: 15px solid #4198b4;
                        background: #F2F2F2 0% 0% no-repeat padding-box;
                        border-left: 15px solid #4198b4;
                        color:  var(--btn-color);
                        opacity: 1;
                        font-size: 15px;
                        
                    }
                }

                .contentProductos
                {
                    width: 100%;
                    box-shadow: 10px 24px 54px #0000000D;
                    .TitleOrden{display: none;}
                    .productos-movil
                        {
                            display: none;
                        }

                    .productos{
                        width: 100%;
                        display: table;
                        border-collapse: collapse;
                        align-items: center;

                        .headersProd{
                            display: table-row;
                            font: normal normal normal 18px/21px Raleway;
                            letter-spacing: 0px;
                            color: #1D4C5A;
                            

                            h1{
                                font-size: 14px;
                            }
                        }
                        .headersProd > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:40px;
                            
                        }
                        
                        .itemproducto{
                            display: table-row;
                            

                            .cell{  
                                vertical-align: middle;

                                .imagen{
                                    display: flex;
                                    align-items: center;
                                    gap:50px;

                                    img{
                                        width: 50px;
                                        height: 50px;
                                    }
                                    .titleProd{
                                        font: normal normal normal 18px Helvetica;
                                        letter-spacing: 0px;
                                        color: #232323;
                                    }
                                }
                                    
                                .cantidadContent{
                                    display: flex;
                                    align-items: center;
                                    gap:20px;

                                    .btn{
                                        border: none;
                                        color: white;
                                        text-decoration: none;
                                        cursor: pointer;      
                                    }

                                    .LabelCantidad{
                                        display: none;
                                    }

                                    .contentBtn{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: var(--btn-color);
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    .contentBtnDes{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: #dcdcdc;
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    h1{
                                        font: normal normal medium 18px/21px Raleway;
                                    }
                                    .contador{
                                        text-align: left;
                                        font: normal normal bold 17px/29px Helvetica;
                                        letter-spacing: 0px;
                                        color: #4198B4;
                                        opacity: 1;
                                    }
                                }
                                
                                .precio{
                                    color: #A7A7A7;
                                    display: flex;
                                    .Oferta{
                                        color: red; 
                                        font-size: 11px;
                                        margin:0;
                                    }
                                }
                                .total{
                                    color: #4198B4;

                                }
                                .delete{
                                    cursor: pointer;

                                }
                            }
                        }

                        .itemproducto > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:20px;
                        }
                    }
                    
                }

                .totalPrecio
                {
                    width: 400px;
                    float: right;
                    margin: 0;
                    display: grid;
                    gap:20px;
                    margin-top:20px;

                    .TotalEnvio{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        font: normal normal normal 16px/43px Helvetica;
                        }
                        .precio{ 
                            font: normal normal normal 30px Helvetica;
                            color: #4198B4;
                        }
                        .bold{
                            font-weight: bold;
                        }
                        .letra {
                            font: normal normal normal 25px Helvetica
                        }
                    }
                    
                }

                .continuar{
                    width: 100%;
                    display: flex;
                    justify-content: right;

                    .button{
                        text-transform: uppercase;
                        color:white;
                        display: flex;
                        background-color: var(--btn-color);
                        justify-content: center;
                        align-items: center;
                        border: 1px solid var(--btn-color);
                        width: 150px;
                        height: 30px;
                        padding: 10px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 14px;
                        margin-top:30px;
                        float:right;
                    
                    }

                    .inactive{
                        background-color: #787a7a;
                    }
                }

                .MsjeError
                {
                    color: #660003;
                    float: left;
                }

                .msjEnvio{
                    font-size: 12px;
                    color: #660003;
                   
                }

            }
        }

    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 390px) and (max-width: 900px) {
        .contentCheckOut{
            width: 100%;
            margin: 5px;

            .rutaPago{
                justify-content: left;
                margin-bottom: 20px;
                .InactiveMobile{
                    display: none;
                }
            }

            .ContentInfoCkeck{
                display: inline;
                margin: 5px;
                gap:10px;
                width: 100%;

                .right{
                    width: 100%;
                    padding: 0px;
                    padding-bottom:20px;

                    .direcciones{
                        margin: 20px;
                    }

                    .FormaDePago{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                        margin-bottom: 20px;
                        transition: opacity 0.8s ease;
                        opacity: 0;

                        .btn{
                            width: 100%;
                            .Active{
                                background-color:var (--btn-color);
                            }
                        }

                        .MetodoSeleccionado{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            display: grid;
                            gap:20px;
                            width: 100%;
                            box-shadow: 5px 5px 10px rgba(90, 90, 90, 0.3);
                            
                            
                            .titleMetodo{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsMetodo{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }


                        }
                    }

                    .contentProductos
                    {

                        width: auto;
                        margin:10px;

                        .TitleOrden{
                            
                            display: flex;
                            font-size: 25px;
                            color: var(--btn-color);
                            margin-bottom:5px;
                            font-weight: 500;
                        }
                        .productos{
                            margin-top: 20px;
                            display: grid;

                            .headersProd{
                                display: inline-table;
                            }
                            
                            .itemproducto{
                                display: inline-table;
                                .cell{  
                                    width: 20%;
                                    text-align: center;
                                    .imagen{
                                        gap:20px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .totalPrecio
                    {
                        display: block;
                        margin: 20px;
                        justify-content: space-between;
                        width: auto;
                        float: inherit;
                        
                        .TotalFull{
                            margin-top: 10px;
                            display: flex;
                            justify-content:inherit;
                            width: 100%;
                            gap:30px;
                        }
                        .TotalEnvio{
                            margin-top: 10px;
                            display: flex;
                            justify-content:inherit;
                            width: 100%;
                            gap:30px;

                            .letra {
                                font: normal normal normal 20px Helvetica
                            }
                        }

                        
                    }
                    .continuar{
                        .button{
                            margin: 20px;
                        }
                    }

                    .SeleccionMetodoMobile{
                        display: flex;
                        margin-bottom:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .mostrar {
                        opacity: 1;
                    }
                }
                .left{
                    width: 100%;
                    padding:0px;
                    margin: 0px;
                    padding-bottom:10px;
                    border-bottom:1px dotted #A7A7A7;
                    background-color: #FFFFFF;
                    color: var(--btn-color);
                    display: inline;

                    .form-container {
                        margin: 20px;   
                        width: auto;
                    }

                    .titlenewTC{
                        font: normal normal 500 17px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;
                        margin:20px;
                        margin-top:10px;
                    }

                    .txtFacturacion
                    {
                        width: auto;
                        display: grid;

                       p{
                        text-align: justify;
                        background-color: #ffffff;
                        margin: 20px;
                        padding: 10px;
                        font: normal normal normal 15px Raleway;
                        color: #232323;
                        border: 2px dotted var(--btn-color);;
                        border-radius: 5px;
                        color:  var(--btn-color);
                       }

                    }

                    .FormaDePago {
                        width: 100%;
                        display: grid;
                        justify-content: center;
                        gap: 20px;
                        margin-bottom: 20px;
                        transition: opacity 0.8s ease;
                        opacity: 0;
                        margin-top: 10px;

                        .btn{
                            height: 100px;
                        }
                    }

                    .title{
                        justify-content: center;
                        font: normal normal bold 18px/22px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;  
                        hr{
                            height: 1px;
                            margin: 0px;
                            background-color: #5797B1; 
                        }     
                    }

                    .SeleccionMobile{
                        display: flex;
                        margin-bottom:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .direcciones{
                        width: auto;
                        display: grid;
                        transition: opacity 0.8s ease;
                        
                        
                        
                        .cardAddress{
                            margin:20px;
                            border: 1px solid rgba(90, 90, 90, 0.3);
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);

                            .iconCard{
                                margin-right: 20px;
                            }
                            .dir{
                                gap:50px;
                                .item{
                                    margin-top:5px;

                                    h1{
                                        font-size: 18px;
                                        font-weight: 500;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .cardAddress.selected{
                            border-left: 15px solid #4198b4;
                            background: #F2F2F2 0% 0% no-repeat padding-box;
                            box-shadow: 0px 3px 6px #00000029;
                            color:  var(--btn-color);
                            opacity: 1;
                            font-size: 15px;
                            
                        }

                        .DireccionSeleccionada{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            background-color: #FFFFFF;
                            display: grid;
                            gap:20px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);
                            
                            
                            .titleDireccion{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsDireccion{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }
                        }

                        .AddAddress{
                            margin:20px;
                            border: 2px dotted var(--btn-color);;
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;

                            .txtlink{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-bottom: 1px solid #FFFFFF;
                                }
                        }
                    }
                    
                    .mostrar {
                        opacity: 1;
                    }
                }

            }
        }
    }
    @media only screen and (max-width: 390px){
        .contentCheckOut{
            width: 100%;
            margin: 5px;

            .rutaPago{
                justify-content: left;
                margin-bottom: 20px;
                .InactiveMobile{
                    display: none;
                }
            }

            .ContentInfoCkeck{
                display: inline;
                margin: 5px;
                gap:10px;
                width: 100%;

                .right{
                    display: grid;
                    width: 100%;
                    padding:0px;
                    padding-bottom: 30px;
                    

                    .direcciones{
                        margin: 20px;
                    }

                    .FormaDePago {
                        width: 100%;
                        display: grid;
                        justify-content: center;
                        gap: 20px;
                        margin-bottom: 20px;
                        transition: opacity 0.8s ease;
                        opacity: 0;
                        margin-top: 10px;

                        .btn{
                            height: 100px;
                            }
                        

                        .MetodoSeleccionado{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            display: grid;
                            gap:20px;
                            width: 100%;
                            box-shadow: 5px 5px 10px rgba(90, 90, 90, 0.3);
                            
                            
                            .titleMetodo{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsMetodo{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }


                        }
                    }

                    .contentProductos
                    {

                        width: auto;
                        margin:10px;

                        .TitleOrden{
                            
                            display: flex;
                            font-size: 25px;
                            color: var(--btn-color);
                            margin-bottom:5px;
                            font-weight: 500;
                        }
                        .productos{
                            margin-top: 20px;
                            display: grid;

                            .headersProd{
                                display: inline-table;
                            }
                            
                            .itemproducto{
                                display: inline-table;
                                .cell{  
                                    width: 20%;
                                    text-align: center;
                                    .imagen{
                                        gap:20px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .totalPrecio
                    {
                        width: auto;
                        margin: 10px;
                       
                        
                        .TotalFull{
                            margin-top: 10px;
                            display: flex;
                            justify-content:inherit;
                            width: 100%;
                            gap:30px;
                        }
                        .TotalEnvio{
                            margin-top: 10px;
                            display: flex;
                            
                            width: 100%;
                            gap:30px;

                            .title{
                                font: normal normal normal 14px Helvetica;
                            }

                            .precio{ 
                                font: normal normal normal 16px Helvetica;
                                color: #4198B4;
                            }
                            .letra {
                                font: normal normal normal 14px Helvetica
                            }
                        }

                        
                    }
                    .continuar{
                        .button{
                            margin: 20px;
                        }
                    }

                    .SeleccionMetodoMobile{
                        display: flex;
                        margin-bottom:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .mostrar {
                        opacity: 1;
                    }
                }
                .left{
                    width: 100%;
                    padding:0px;
                    margin: 0px;
                    padding-bottom:10px;
                    border-bottom:1px dotted #A7A7A7;
                    background-color: #FFFFFF;
                    color: var(--btn-color);
                    display: inline;

                    .form-container {
                        margin: 20px;   
                        width: auto;
                    }

                    .titlenewTC{
                        font: normal normal 500 17px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;
                        margin:20px;
                    }

                    .txtFacturacion
                    {
                        width: auto;
                        display: grid;

                       p{
                        text-align: justify;
                        background-color: #ffffff;
                        margin: 20px;
                        padding: 10px;
                        font: normal normal normal 15px Raleway;
                        color: #232323;
                        border: 2px dotted var(--btn-color);;
                        border-radius: 5px;
                        color:  var(--btn-color);
                       }

                    }

                    .FormaDePago { 
                        width: 100%;
                        display: grid;
                        justify-content: center;
                        gap: 20px;
                        margin-bottom: 20px;
                        transition: opacity 0.8s ease;
                        opacity: 0;
                    }

                    .title{
                        justify-content: center;
                        font: normal normal bold 18px/22px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;  
                        hr{
                            height: 1px;
                            margin: 0px;
                            background-color: #5797B1; 
                        }     
                    }

                    .SeleccionMobile{
                        display: flex;
                        margin-bottom:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .direcciones{
                        width: auto;
                        display: grid;
                        transition: opacity 0.8s ease;
                        
                        
                        
                        .cardAddress{
                            margin:20px;
                            border: 1px solid rgba(90, 90, 90, 0.3);
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);

                            .iconCard{
                                margin-right: 20px;
                            }
                            .dir{
                                gap:50px;
                                .item{
                                    margin-top:5px;

                                    h1{
                                        font-size: 18px;
                                        font-weight: 500;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .cardAddress.selected{
                            border-left: 15px solid #4198b4;
                            background: #F2F2F2 0% 0% no-repeat padding-box;
                            box-shadow: 0px 3px 6px #00000029;
                            color:  var(--btn-color);
                            opacity: 1;
                            font-size: 15px;
                            
                        }

                        .DireccionSeleccionada{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            background-color: #FFFFFF;
                            display: grid;
                            gap:20px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);
                            
                            
                            .titleDireccion{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsDireccion{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }
                        }

                        .AddAddress{
                            margin:20px;
                            border: 2px dotted var(--btn-color);;
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;

                            .txtlink{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-bottom: 1px solid #FFFFFF;
                                }
                        }
                    }
                    
                    .mostrar {
                        opacity: 1;
                    }
                }

            }
        }
    }

}

.modal-PagoErr{
        height: 100%;
        border: '2px solid rgb(210, 67, 67)';
        display: grid;
        color:#545454;
        padding : 0px ;
        align-items: center;
        
    .HeaderPagoErr{
        display: flex;
        padding :0px 20px 0px 20px;
        font-weight: 500;
    }
    .mejerror{
        display: flex;
        align-items: center;
        gap:10px;
        font: normal normal 500 16px/18px Helvetica;
        padding :0px 20px 0px 20px;
    }
    .btn{
        padding :0px 20px 0px 20px;
        border-top: 1px solid #383838;
        height: 100%;
        
        .txt{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        
    }
}

.contentModal{
    display: inline;
    .button{
        display: flex;
        justify-content: right;

        .cerrar{
            float: right;
            font-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
    .modal{
        display: flex;
        justify-content: center;
    }
}



