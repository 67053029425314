    .contentLogin
    {
        width: 521px;
        height: 526px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 10px 24px 54px #0000000D;
        background: #F7F7F7;
        margin-bottom:50px;
        margin-top:50px;

        .titulo{
            font: normal normal bold 40px/39px Helvetica;
            letter-spacing: 0px;
            color: #1D4C5A;
            text-align: center;
        }
        .form{
            
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:10px;

            input{
                width: 420px;
                height: 50px;
                font-size: 18px;
                border: 1px solid #A7A7A7;
            }
            button{
                color:white;
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 298px;
                height: 60px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
                margin-top:30px;
            }

        }
        .Links{
            display: grid;
            gap:20px;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            text-align: center;

            .Link{
                color: #1D4C5A;
                text-decoration: underline;
            }
        }
        .errorlogin{
            color:rgb(161, 1, 1);
            font-size: 17px;
        }

        @media only screen and (min-width: 320px) and (max-width: 900px) {
            width: 95%;
            padding: 10px;
            .form
            {
                width: 100%;
                input{
                    width: 100%;
                    width: 320px;  
                    height: 40px;
                    font-size: 16px;
                }       
            }
        }
        @media only screen and (max-width: 380px) {
            width: 95%;
            padding: 10px;
            .form
            {
                width: 100%;
                input{
                    width: 100%;                    
                    height: 40px;
                    font-size: 16px;
                    
                }       
                button{
                    width: auto;
                    height: 60px;
                    
                }
    
            }
        }
    }