.WrapperPedido{
    justify-content: center;
    display: flex;
    margin-bottom:150px;
    margin-top:30px;

    .contentPedido
    {
        text-align: center;
        .titPedido{
            font-size: 30px;
            color: var(--btn-color);;
        }
        .SegPedido{
            height: 30px ;
            .input{
                height: 100% ;
                border: 1px solid var(--btn-color);
            }
            .button{
                height: 100% ;
                background-color: var(--btn-color);
                color: #FFFFFF;
                border: 1px solid var(--btn-color);
                cursor: pointer;
            }
            .ErrValida{
                color: brown;
            }
        }

    }
}