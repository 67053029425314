.wrapperMetodoPago{
    width: 100%;
    margin-top: 30px;

    .tituloMetodoPago{
        text-align: left;
        font: normal normal bold 24px/29px Helvetica;
        letter-spacing: 0.5px;
        color: #4198B4;
        margin:20px;
    }

    .ContentMetodoPago{
        width: 100%;
        display: grid;
        gap:30px;
        margin-top: 30px;
        .Link{text-decoration: none;}

        .AccountMetodoPago{
            gap:20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: center;
            margin:20px;

            .cardAddress{
                height: 80px;
                border: 1px solid #A7A7A7;
                opacity: 1;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 3px 6px 6px #00000029;
                color:  var(--btn-color);
                
                .title{
                    width: 100%;
                    display: flex;
                    justify-content: left;
                    margin: 10px;
                    font-size: 18px;
                    font-weight: 500;
                }

                .tc
                {
                    display:flex;
                    justify-content: space-between;
                    margin: 10px;
                
                    .dir{
                        align-items: center;
                        display: flex;
                        .iconCard{

                        }
                        .item{
                            margin-bottom: 5px;
                            padding-left: 10px;
                            text-align: left;
                            font: normal normal normal 16px Raleway;
                            letter-spacing: 0px;
                            color: #232323;
                        }
                    }
                    .acciones
                    {
                        display: flex;
                        justify-content:right;
                        align-items: center;
                       
                        .itemAccion{
                            font: normal normal bold 14px/17px Helvetica;
                            letter-spacing: 0.44px;
                        }
                        .delete{
                            color: #B44141;
                        }
                    }
                   
                }
            }
        }

        .ContentBtn{
            margin-left: 20px;
            .add{
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 248px;
                height: 30px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
                letter-spacing: 0px;
                color: #FFFFFF;
            }
        }
    }
    .ContentAddMetodoPago{
        width: 100%;
        display: grid;
        
        .AddMetodoPago{
            justify-content: center;
            display: flex;
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        
        .ContentMetodoPago{
    
            .AccountMetodoPago{
                gap:20px;
                display: table-column;
                justify-content: center;
                margin:20px;
    
                .cardAddress{
                    margin: 0px 10px 20px 10px;
                }
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                margin: 0px;
                .add{
                    width: 200px;

                }
            }
        }
        .ContentAddMetodoPago{
            width: 100%;
            display: inline;
    
            .AddAddress{
                justify-content: center;
                display: flex;
            }
        }
        
    }
    @media only screen and (max-width: 320px) {
        .ContentMetodoPago{
    
            .AccountMetodoPago{
                gap:20px;
                display: table-column;
                justify-content: center;
                margin:20px;
    
                .cardAddress{
                    margin: 0px 10px 20px 10px;
                }
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                margin: 0px;
                .add{
                    width: 200px;

                }
            }
        }
        .ContentAddMetodoPago{
            width: 100%;
            display: inline;
    
            .AddAddress{
                justify-content: center;
                display: flex;
            }
        }
    }
}

