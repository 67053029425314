@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Helvetica);

:root {
  --btn-color: #1d4c5a;
  --btn-color-hover: #33839b;
  --Font-Menu: normal normal normal 20px/30px Raleway;
  --Font-top: normal normal normal 18px/22px Helvetica;
  --Font-top-Min: normal normal normal 14px/20px Helvetica;
  /*Nosotros*/
  --Font-h1: normal normal bold 35px/64px Helvetica;
  --font-span: normal normal normal 17px/19px Raleway;
  --font-h1-min: normal normal bold 20px/25px Helvetica;
  --font-span-min: normal normal normal 14px/16px Raleway;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-to-top {
  z-index: 99;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: #dfdede;
  opacity: 0.8;
  color: #fff;
  border: none;
  border-radius: 30%;
  padding: 10px 13px 10px 13px;
  animation: fadeIn 0.3s ease; /* Animación de desvanecimiento */
  transition: opacity 0.3s ease; /* Transición de opacidad */
  opacity: 0.8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

html {
  scroll-behavior: smooth;
}

.Resumen {
  display: grid;
  width: 70%;
  gap: 50px;
  justify-content: center;
  margin: auto;
  background-color: aliceblue;
  padding: 50px;

  .IdPedido {
    font-size: 30px;
    font-weight: bold;
    color: var(--btn-color);
  }

  .ItemResumen {
    .title {
      font-size: 20px;
    }

    .info {
    }

    .contentProductos {
      width: 100%;
      .TitleOrden {
        display: none;
      }
      .productos-movil {
        display: none;
      }

      .productos {
        width: 100%;
        display: table;
        border-collapse: collapse;
        align-items: center;

        .headersProd {
          display: table-row;
          font: normal normal normal 18px/21px Raleway;
          letter-spacing: 0px;
          color: #1d4c5a;

          h1 {
            font-size: 14px;
          }
        }
        .headersProd > div {
          display: table-cell;
          padding: 5px;
          padding-bottom: 40px;
        }

        .itemproducto {
          display: table-row;

          .cell {
            vertical-align: middle;

            .imagen {
              display: flex;
              align-items: center;
              gap: 50px;

              img {
                width: 50px;
                height: 50px;
              }
              .titleProd {
                font: normal normal normal 18px Helvetica;
                letter-spacing: 0px;
                color: #232323;
              }
            }

            .cantidadContent {
              display: flex;
              align-items: center;
              gap: 20px;

              .btn {
                border: none;
                color: white;
                text-decoration: none;
                cursor: pointer;
              }

              .LabelCantidad {
                display: none;
              }

              .contentBtn {
                width: 30px;
                height: 30px;
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
              }
              .contentBtnDes {
                width: 30px;
                height: 30px;
                display: flex;
                background-color: #dcdcdc;
                justify-content: center;
                align-items: center;
              }
              h1 {
                font: normal normal medium 18px/21px Raleway;
              }
              .contador {
                text-align: left;
                font: normal normal bold 17px/29px Helvetica;
                letter-spacing: 0px;
                color: #4198b4;
                opacity: 1;
              }
            }

            .precio {
              color: #a7a7a7;
              display: flex;
              .Oferta {
                color: red;
                font-size: 11px;
                margin: 0;
              }
            }
            .total {
              color: #4198b4;
            }
            .delete {
              cursor: pointer;
            }
          }
        }

        .itemproducto > div {
          display: table-cell;
          padding: 5px;
          padding-bottom: 20px;
        }
      }
    }
    .totalPrecio {
      width: 300px;
      float: right;
      margin: 0;
      display: grid;
      gap: 20px;
      margin-top: 20px;
      font: normal normal normal 16px/20px Helvetica;

      .TotalEnvio {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          color: #232323;
          text-transform: uppercase;
          font: normal normal normal 14px/20px Helvetica;
        }
        .precio {
          color: #4198b4;
        }
        .bold {
          font-weight: bold;
        }
        .letra {
        }
      }
    }
  }
}
