.wrapperFactura{
    width: 100%;
    margin-top: 30px;

    .tituloFactura{
        text-align: left;
        font: normal normal bold 24px/29px Helvetica;
        letter-spacing: 0.5px;
        color: #4198B4;
        margin:20px;
    }

    .ContentFactura{
        width: 100%;
        display: grid;
        gap:30px;
        margin-top: 30px;
        .Link{text-decoration: none;}

        .AccountFactura{
            gap:20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: center;
            margin:20px;

            .cardAddressPerfil{
                border: 1px solid #A7A7A7;
                opacity: 1;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 3px 6px 6px #00000029;
                color:  var(--btn-color);
                display: flex;
                position : relative;
                flex-direction: column;
                
                .dir{
                    width: 100%;
                    height: 150px;
                    display: flex;
                    flex-direction: column;

                    .item{
                        margin-bottom: 5px;
                        margin: 10px;
                        text-align: left;
                        letter-spacing: 0px;
                        font-size: 15px;
                        font: normal normal normal 15px Raleway;
                        color:  var(--btn-color);

                        h1{
                            font: 17px Helvetica;
                            margin:0;   
                        }
                        
                    }
                    .title{
                        margin-bottom: 15px;
                    }

                    .acciones
                    {
                        display: flex;
                        justify-content: space-between;
                        height: 30%;
                        align-items: flex-end;
                        margin: 10px;
                        .itemAccion{
                            font: normal normal bold 14px/17px Helvetica;
                            letter-spacing: 0.44px;
                        }
                        .delete{
                            color: #B44141;
                        }
                    }
                }
            }
        }

        .ContentBtn{
            margin-left: 20px;
            .add{
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 248px;
                height: 30px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
                letter-spacing: 0px;
                color: #FFFFFF;
            }
        }
    }
    .ContentAddFacturacion{
        width: 100%;
        display: grid;
        
        .AddFacturacion{
            justify-content: center;
            display: flex;
        }
    }
    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        
        .ContentFactura{
            .AccountFactura{
                gap:20px;
                display: table-column;
                justify-content: center;
                margin:20px;
    
                .cardAddressPerfil{
                    margin: 0px 10px 20px 10px;
                }
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                margin: 0px;
                .add{
                    width: 250px;

                }
            }
        }

        .ContentAddFacturacion{
            width: 100%;
            display: inline;
    
            .AddFacturacion{
                justify-content: center;
                display: flex;
            }
        }
        
    }
    @media only screen and (max-width: 320px) {
        .ContentFactura{
            .AccountFactura{
                gap:20px;
                display: table-column;
                justify-content: center;
                margin:20px;
    
                .cardAddressPerfil{
                    margin: 0px 10px 20px 10px;
                }
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                margin: 0px;
                .add{
                    font-size: 15px;
                    width: 220px;

                }
            }
        }

        .ContentAddFacturacion{
            width: 100%;
            display: inline;
    
            .AddFacturacion{
                justify-content: center;
                display: flex;
            }
        }
    }
}

