.wrapperSeccion3
{
    display: flex;
    justify-content: center;
    background: #F7F7F7;
    opacity: 1;
    padding-top: 60px;
    padding-bottom: 60px;

    .contenedorSn3
    { 
        width: 95%;
        align-items: center;
        max-width: 1620px;

        .contentCalidad{
            width: 100%;
            display: flex;

            .left{

                width: 100%;
                .tit{
                    width: 100%;
                    margin-bottom: 10px;

                    .tituloh1{
                        
                        color: #232323;
                        opacity: 1;
                        margin-bottom: 10px;

                        h1{
                            font: normal normal bold 35px/44px Helvetica;
                            position:relative;
                            display: inline-block;
                        }

                        h1::after{
                            content: '';
                            position: absolute;
                            width: 30%;
                            height: 3px;
                            background-color: var(--btn-color);
                            top:15px;
                            margin-left: 5px;
                        } 
                    }
                    .textospan{
                        font: normal normal normal 16px/24px Raleway;
                        letter-spacing: 0px;
                        color: #232323;
                        opacity: 1;
                    }
                }
                .ContImages
                {
                    display: flex;
                    height: 290px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    gap:20px;
                    align-items: center;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 15px;
                    max-width: 860px;
                    position: absolute;
                    z-index: 1;

                    .Contimg{
                        width: 100%;
                        position: relative;
                        display: flex;
                    
                        .img{
                            width: 100%;
                            max-width: 269px;
                            position: relative;
                            display: flex;

                            .detallesimg
                            {
                                background: #f2f2f2;
                                position:absolute;
                                letter-spacing: 0.9px;
                                color: #232323;
                                opacity: 1;

                                h1{
                                    font: normal normal 600 13px/20px Raleway;
                                    padding-left: 20px;
                                    padding-right: 20px;
                                }
                                .Link{
                                    text-decoration: none;
                                    color: #232323;
                                }
                            }
                            
                            img{
                            width: 100%;
                            height: 266px;
                            }
                            img:hover{
                                width: 100%;
                                opacity: 0.5;
                            }

                            
                        }

                        .imgtext{
                            font: normal normal bold 20px/24px Helvetica;
                            letter-spacing: 0px;
                            color: #232323;
                            opacity: 1;
                            position:absolute;
                            z-index: 10;
                            margin-top: 270px;
                        }

                    }

                }
            }

            .imgPrincipal
            {
                height: 100%;
                width: 100%;

                .imgright{
                    img{
                        width: 100%;
                    
                        height: 550px;
                    }
                }
            }
            
        }
        .contentBtn{
            z-index:1;
            margin-top:20px;
            .btn{
                border: none;
                color: white;
                padding: 6px 10px;
                text-decoration: none;
                cursor: pointer;       
                background-color: var(--btn-color);
            }
            .iconCat{
                font-size: 13px;
            }
        }

        
    }

    @media only screen and (min-width: 380px) and (max-width: 1000px) {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 5px;
        .contenedorSn3
        {
            width: 100%;
            
            .contentCalidad{
                height: 620px;
                
                .left{

                    .tit{.tituloh1{
                        h1{font: normal normal bold 22px Helvetica;}
                        h1::after{
                            width:20%;
                            top:10px;

                        }
                    }
                    .textospan{
                        font: normal normal normal 15px/24px Raleway;
                    }
                }
                   
                    
                    .ContImages
                    {
                        width: 100%;
                        height: auto;
                        padding:0;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        position:initial;
                        
                        
                        .Contimg{
                            .img{
                                margin-bottom: 15px;
                                width: 100%;
                                max-width: 240px;
                                position: relative;
                                display: flex;
    
                                .detallesimg
                                {
                                    background: #f2f2f2;
                                    position:absolute;
                                    letter-spacing: 0.9px;
                                    color: #232323;
                                    opacity: 1;
    
                                    h1{
                                        font: normal normal 600 13px/20px Raleway;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                    }
                                    .Link{
                                        text-decoration: none;
                                        color: #232323;
                                    }
                                }
                                
                                img{
                                width: 100%;
                                height: 200px;
                                }
                                img:hover{
                                    width: 100%;
                                    opacity: 0.5;
                                }
                            }
                            .imgtext{
                                font: normal normal bold 20px/24px Helvetica;
                                letter-spacing: 0px;
                                color: #232323;
                                opacity: 1;
                                position:absolute;
                                z-index: 10;
                                margin-top: 200px;
                            }
                        }
                    }
                }

                .imgPrincipal{
                    display: none;}
            }
            .contentBtn{margin-top: 80px;}
            
        } 
    }
    @media only screen and (max-width: 380px) {
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 5px;
        .contenedorSn3
        {
            width: 100%;
            
            .contentCalidad{
                height: 620px;
                
                .left{

                    .tit{.tituloh1{
                            h1{font: normal normal bold 22px Helvetica;}
                            h1::after{
                                width: 20%;
                                top:10px;

                            }
                    }
                    .textospan{
                        font: normal normal normal 15px/24px Raleway;
                    }
                    }
                    
                    
                    .ContImages
                    {
                        width: 100%;
                        padding:0;
                        height: auto;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        position:initial;
                        
                        .Contimg{
                            .img{
                                margin-bottom: 15px;
                                width: 100%;
                                max-width: 240px;
                                position: relative;
                                display: flex;
    
                                .detallesimg
                                {
                                    background: #f2f2f2;
                                    position:absolute;
                                    letter-spacing: 0.9px;
                                    color: #232323;
                                    opacity: 1;
    
                                    h1{
                                        font: normal normal 600 13px Raleway;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                    }
                                    .Link{
                                        text-decoration: none;
                                        color: #232323;
                                    }
                                }
                                
                                img{
                                width: 100%;
                                height: 200px;
                                }
                                img:hover{
                                    width: 100%;
                                    opacity: 0.5;
                                }
                            }
                            .imgtext{
                                font: normal normal bold 20px/24px Helvetica;
                                letter-spacing: 0px;
                                color: #232323;
                                opacity: 1;
                                position:absolute;
                                z-index: 10;
                                margin-top: 200px;
                            }
                        }
                    }
                }

                .imgPrincipal{
                    display: none;}
            }
            .contentBtn{margin-top: 80px;}
            
        } 
    }
}