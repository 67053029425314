.wrapperPedidos{
    width: 100%;
    margin-top: 30px;


    .tituloPedidos{
        text-align: left;
        font: normal normal bold 24px/29px Helvetica;
        letter-spacing: 0.5px;
        color: #4198B4;
        margin:20px;
    }

    .ContentPedidos{
        width: 100%;
        display: grid;
        gap:30px;

        .filtros{
            margin:10px;
            .txtFiltro{
                color: #FFFFFF;
                display: grid;
                grid-template-columns: repeat(4,1fr);
                gap:10px;
                
                .item{
                    display: grid;
                    background-color: #4198B4;
                    padding: 20px;
                    justify-content: center;
                    cursor: pointer;
                }

                .item:hover{
                    background-color: var(--btn-color);
                }
                .active{
                    background-color: var(--btn-color);
                }
            }
        }

        .Link{text-decoration: none;}

        .InfoPedidos{
            gap:20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            margin-left: 10px;
            margin:20px;

            .errtxt{
                color: #A7A7A7;
                display: grid;
                gap:10px;
                justify-content: center;

                .txt{
                    display: flex;
                    justify-content: center;
                }
            }

            .cardPedidos{
                height: auto;
                border: 1px solid #A7A7A7;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 3px 6px 6px hsla(0, 0%, 0%, 0.161);
                opacity: 1;
            
                .infoCard
                {
                    
                    height: 49px;
                    background: #4198B4 0% 0% no-repeat padding-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap:40px;
                    padding : 10px;
            
                    .item{
                        text-align: left;
                        font: normal normal normal 12px/18px Helvetica;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        .title{
                            text-align: left;
                            font: normal normal bold 12px/18px Helvetica;
                            letter-spacing: 0px;
                            color: #FFFFFF;
                        }
                        .cancelado{ 
                            font-weight: bold;
                            color:brown;
                            letter-spacing: 0.5px;
                        }
                        .btn{
                            background: #1D4C5A 0% 0% no-repeat padding-box;
                            width: 129px;
                            height: 31px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                        .btnNew{
                            
                            width: 129px;
                            height: 31px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                
                }
            
                .detalleCard{
  
                    padding : 10px;
                    display: grid;
                    gap:10px;

                    .InfoDetalle{

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap:5px;

                        .ProductImages{
                            display: flex;
                            gap: 5px;
                            align-items: center;
                            
                            .imagenPedido{
                                align-items: center;
                                display: flex;
                                img{
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                            .masImagenes{
                                width: 40px;
                                height: 60px;
                                background-color: #a7a7a7;
                                align-items: center;
                                justify-content: center;
                                display: flex;
                                cursor: pointer;
                                
                                span{
                                    text-align: left;
                                    font: normal normal bold 20px Helvetica;
                                    letter-spacing: 0px;
                                    color: #2D2D2D;
                                }
                            }
                        }
                        .BtnAcciones{
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap:3px;
                            
                            .btnDetalles {
                                    padding: 1px;
                                    width: auto;
                                    height: 60px;
                                    background: #1D4C5A 0% 0% no-repeat padding-box;
                                    opacity: 1;
                                    display: grid;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    padding: 0px 5px 0px 5px;
                                    letter-spacing: 0px;
                                    color: #FFFFFF;
                                    text-transform: uppercase;
                                }

                                .icono{
                                    display: flex;
                                    justify-content: center;
                                }

                                .deshabilitado{
                                    background-color: #818181;
                                }
                                .txt{
                                    display: flex;
                                    justify-content: center;
                                    font: normal normal normal 8px Helvetica;
                                }
                            }
                    }

                    .factura{
                            display: flex;
                            justify-content: right;
                            
                        .item{ 
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: var(--btn-color);

                            .iconoFactura{
                                cursor: pointer;
                            }
                            .txtFactura
                            {
                                font-size: 13px;
                            }

                            .deshablitada{
                                color:#A7A7A7;
                            }
                        }
                    }
            
                }
            }
        }
        
    }

    .contentModal{
        display: inline;
        .button{
            display: flex;
            justify-content: right;
    
            .cerrar{
                float: right;
                font-size: 20px;
                border: none;
                cursor: pointer;
            }
        }
        .modal{
            display: flex;
            justify-content: center;
        }
    }

    @media only screen and (min-width: 800px) and (max-width: 1100px){
        .ContentPedidos{
            display:grid;
            justify-content: center;
            margin: 0px;

             .filtros{
                margin:10px;
                
                .txtFiltro{
                    display: flex;
                    gap:10px;
                    
                    label{color: var(--btn-color);}
                }
            }


            .detallePedido{
                height: auto;
                padding: 20px;
            }
            
            .InfoPedidos{
                display: table-row;
                width: 100%;

                .cardPedidos{
                    height: auto;
                    margin: 0px 0px 20px 0px;
                
                    .infoCard
                    {
                        .item{
                            display: grid;
                            gap:10px;
                            font: normal normal normal 12px Helvetica;
                            .btn{
                                width: auto;
                                padding: 5px;
                                height: auto;
                                justify-content: center;
                                display: flex;

                            }
                            .title{
                                font: normal normal bold 12px Helvetica;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 340px) and (max-width: 800px) {
        
        .ContentPedidos{
            display:grid;
            //justify-content: center;
            margin: 0px;

            .filtros{
                margin:10px;
                .txtFiltro{
                    display: flex;
                    gap:10px;

                    label{color: var(--btn-color);}
                }
            }

            .detallePedido{
                height: auto;
                //padding: 20px;
            }
            
            .InfoPedidos{
                display: table-row;
                width: auto;
                margin: 0px;

                .cardPedidos{
                    height: auto;
                    margin: 0px 0px 20px 0px;
                
                    .infoCard
                    {
                        gap:20px;
                        .item{
                            display: grid;
                            gap:10px;
                            font: normal normal normal 12px Helvetica;
                            .btn{
                                width: auto;
                                padding: 5px;
                                height: auto;
                                justify-content: center;
                                display: flex;

                            }
                            .title{
                                font: normal normal bold 12px Helvetica;
                            }
                        }
                    }
                }
            }
        }
        
    }
    @media only screen and (max-width: 340px) {
        .ContentPedidos{
            display:inline;
            justify-content: center;
            margin: 0px;

            .filtros{
                margin:10px;
                .txtFiltro{
                    display: flex;
                    gap:10px;

                    label{color: var(--btn-color);}
                    
                }
            }

            .detallePedido{
                height: auto;
                //padding: 20px;
            }
            
            .InfoPedidos{
                display: inline;
                width: 100%;

                .cardPedidos{
                    height: auto;
                    margin: 0px 0px 20px 0px;
                
                    .infoCard
                    {
                        display: inherit;
                        height: auto;
                        gap:20px;
                        .item{
                            font: normal normal normal 10 Helvetica;
                            display: flex;
                            gap:10px;
                            .btn{
                                width: auto;
                                padding: 5px;
                                height: auto;
                                justify-content: center;
                                display: flex;

                            }
                        }
                    }
                }
            }
        }
    }
}