.modal-header {
    background-color:var(--btn-color);
    color: #fff;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title{
        font-size: 18px;
        font-weight: 500;
    }
    .btn{
            
        border: none;
        width: 100px;
        text-decoration: none;
        cursor: pointer;    
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--btn-color);
        padding: 5px 5px;
        color: white;  
        float: right;    
    }

    .Link{
        text-decoration: none;
        color: #fff;
    }

}



.modal-body {
    padding: 20px;
    justify-content: center;
    display: grid;
    margin-bottom: 20px;

    .contentFacturacion{
        padding:0px;
    }

    .txtNuevaFactura
    {
        font: normal normal italic 15px Helvetica;
       display: flex;
       justify-content: center;
    }

    .DataFactura{
        justify-content: center;
        margin-top: 3%;

        .item{
            display:grid;
            grid-template-columns: 1.5fr 2fr;
            justify-content: center;
            width: auto;
            gap:10px;

            .lable{
                display: flex;
                justify-content: right;

            }
            .text{
                display: flex;
                justify-content: left;
            }
        }
    }

    .btns{
        display: flex;
        gap:20px;
        justify-content: center;
        margin-top: 3%;
        .btn{
            
            border: none;
            width: 100px;
            text-decoration: none;
            cursor: pointer;    
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--btn-color);
            padding: 5px 5px;
            color: white;  
            float: right;    
        }
    }

    .re-facturar{
        font: normal normal italic 15px Helvetica;
       display: flex;
       justify-content: center;
       margin-top: 3%;
    }
    
    .btn{
            
        border: none;
        width: 100px;
        text-decoration: none;
        cursor: pointer;    
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--btn-color);
        padding: 5px 5px;
        color: white;  
        float: right;    
    }
    
    .txt
    {
        font-size: 16px;
        font-weight: 500;
    }

    .Seguimiento{

        display: grid;
        justify-content: center;
        color: #646363;
        

        .infoSeguimiento{
            font-size: 14px;
            display: grid;
            gap:3px;
            .item
            {
                display: flex;
                gap:10px;
            }
        }
        .ContetEventos{
            display: grid;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
        .title{
            font-size: 18px;
            font-weight: 500;
            border: 1px solid #dad6d6;
        }
        .Eventos{
            font-size: 13px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            width: 100%;
            border: 1px solid #dad6d6;

            .item{
                padding: 10px;
            }
        }
        }   
    }
}

.modal-footer {
    background-color: var(--btn-color);;
    color: #fff;
    padding: 10px;
    text-align: right;

    
}


@media only screen and (min-width: 900px) and (max-width: 1100px){
}
@media only screen and (min-width: 320px) and (max-width: 900px) {
    .modal-body {

        .contentFacturacion{
            width: auto;
            
        }
        .Seguimiento{

            display: grid;
            justify-content: center;
            color: #646363;
            
    
            .infoSeguimiento{
                font-size: 14px;
                display: grid;
                gap:3px;
                .item
                {
                    display: flex;
                    gap:10px;
                }
            }
            .ContetEventos{
                display: grid;
                grid-template-rows: auto;
                gap:10px;
                justify-content: center;
                width: 100%;
                margin-top: 20px;

            .title{
                display: none;
            }
            .Eventos{
                font-size: 13px;
                display: inline;
                width: 100%;
                border: 1px solid #dad6d6;
    
                .item{
                    padding: 3px;
                }
            }
            }   
        }
    }
    
    
}
@media only screen and (max-width: 320px) {
}
