.dropdown-menu__list {
    display: none;
  }
  
  .dropdown-menu.show .dropdown-menu__list {
    display: block;
  }

  @media only screen and (max-width: 1000px){
    .dropdown-menu__button {
      display: block;
      width: 100%;
      text-align: left;
      background-color: white;
      border: 0;
      cursor: pointer;
    }
    
    nav {
      z-index: 998;
      transition: all 0.4s ease-in-out;
      position: absolute;
      top: 10;
      left: -100%;
      width: 100%;
      background-color: white;
      text-align: center;
      padding-top:10px;
      box-shadow: -10px 10px 25px -2px rgba(189,189,189,0.71);
      -webkit-box-shadow: -10px 10px 25px -2px rgba(189,189,189,0.71);
      -moz-box-shadow: -10px 10px 25px -2px rgba(189,189,189,0.71);
    }

    .menu-open nav{
      left: 0;
    } 

    nav ul {
      margin-top: 20%;
      list-style: none;
      padding: 0;
      margin: 0;
      color: var(--btn-color);
      font-weight: 500;
      font-size: 18px;
    }
    
    nav li {
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
    }
    
    nav li:last-child {
      border-bottom: none;
    }
  }

  
  
  
  
  
  
  