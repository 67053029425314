.contentDetallePedido{
    justify-content: center;
    padding: 20px;


    .Resumen{
        display: grid;
        width: auto;
        gap:20px;
        justify-content: inherit;
        margin: auto;
        background-color:aliceblue;
        padding: 20px;
        margin-top:10px;
        
        .ItemResumen{

            .title{
                font-size: 20px;
            }

            .info{

            }

            .contentProductos
            {
                width: 100%;
                .TitleOrden{display: none;}
                .productos-movil
                    {
                        display: none;
                    }

                .productos{
                    width: 100%;
                    display: table;
                    border-collapse: collapse;
                    align-items: center;

                    .headersProd{
                        display: table-row;
                        font: normal normal normal 18px/21px Raleway;
                        letter-spacing: 0px;
                        color: #1D4C5A;
                        

                        h1{
                            font-size: 14px;
                        }
                    }
                    .headersProd > div {
                        display: table-cell;
                        padding: 5px;
                        padding-bottom:40px;
                        
                    }
                    
                    .itemproducto{
                        display: table-row;
                        

                        .cell{  
                            vertical-align: middle;

                            .imagen{
                                display: flex;
                                align-items: center;
                                gap:50px;

                                img{
                                    width: 50px;
                                    height: 50px;
                                }
                                .titleProd{
                                    font: normal normal normal 18px Helvetica;
                                    letter-spacing: 0px;
                                    color: #232323;
                                }
                            }
                                
                            .cantidadContent{
                                display: flex;
                                align-items: center;
                                gap:20px;

                                .btn{
                                    border: none;
                                    color: white;
                                    text-decoration: none;
                                    cursor: pointer;      
                                }

                                .LabelCantidad{
                                    display: none;
                                }

                                .contentBtn{
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    background-color: var(--btn-color);
                                    justify-content: center;
                                    align-items: center;
                                    
                                }
                                .contentBtnDes{
                                    width: 30px;
                                    height: 30px;
                                    display: flex;
                                    background-color: #dcdcdc;
                                    justify-content: center;
                                    align-items: center;
                                    
                                }
                                h1{
                                    font: normal normal medium 18px/21px Raleway;
                                }
                                .contador{
                                    text-align: left;
                                    font: normal normal bold 17px/29px Helvetica;
                                    letter-spacing: 0px;
                                    color: #4198B4;
                                    opacity: 1;
                                }
                            }
                            
                            .precio{
                                color: #A7A7A7;
                                display: flex;
                                .Oferta{
                                    color: red; 
                                    font-size: 11px;
                                    margin:0;
                                }
                            }
                            .total{
                                color: #4198B4;

                            }
                            .delete{
                                cursor: pointer;

                            }
                        }
                    }

                    .itemproducto > div {
                        display: table-cell;
                        padding: 5px;
                        padding-bottom:20px;
                    }
                }
                
            }
            .totalPrecio
            {
                width: 300px;
                float: right;
                margin: 0;
                display: grid;
                gap:20px;
                margin-top:20px;
                font: normal normal normal 16px/20px Helvetica;

                .TotalEnvio{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .title{
                    color: #232323;
                    text-transform: uppercase;
                    font: normal normal normal 14px/20px Helvetica;
                    }
                    .precio{ 
                        
                        color: #4198B4;
                    }
                    .bold{
                        font-weight: bold;
                    }
                    .letra {
                        
                    }
                }
                
            }
        }
        .TerminosYCondiciones{
            .top{
                display: flex;
                grid-template-columns: repeat(1, 1fr);
                grid-column-gap:10px;
                justify-content: space-between;
            .contentToggle{

                display: flex;
                align-items: center;
                gap:10px;

                .toggle-container {
                    position: relative;
                    display: inline-block;
                }
                
                .toggle-button {
                    position: relative;
                    appearance: none;
                    background-color: #ccc;
                    border: none;
                    width: 50px;
                    height: 24px;
                    border-radius: 24px;
                    outline: none;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }
                
                .slider {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: white;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                    transition: transform 0.3s ease;
                }
                
                .toggle-button.toggled-on {
                    background-color: #3fdabf; /* Color de fondo cuando está encendido */
                }
                
                .toggle-button.toggled-off {
                    background-color: #ccc; /* Color de fondo cuando está apagado */
                }
                
                .toggle-button.toggled-on .slider {
                    transform: translateX(26px); /* Desplaza el slider a la derecha cuando está encendido */
                }

                .txtToggle{

                }
            }

            .btns{
                display: flex;
                .button{
                    text-transform: uppercase;
                    color:white;
                    display: flex;
                    background-color: var(--btn-color);
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--btn-color);
                    width: 150px;
                    height: 30px;
                    padding: 10px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 14px;
                    margin-top:30px;
                    float:right;
                    margin-right: 5px;
                }
                
                .inactive{
                    background-color: #787a7a;
                }

                .continuar{

                }
                .atras{
                    .button{
                    background-color: #A7A7A7;
                    border: 1px solid #A7A7A7;
                    }
                }

            }
            }
            .bottom{
                margin-top: 10px;
                .txtFactura{
                    font-size: 14px;
                    width: 50%;
                }
            }
        }
    }

    

    .regresar{
        .Link{text-decoration: none;}
        margin-bottom: 20px;
    }
    .Pedido{
       
        display: grid;
        gap:20px;
        color: #FFFFFF;
        justify-content: space-between;
        

        .noPedido{
            font: normal normal bold 24px/29px Helvetica;
            color: #4198b4;
            text-align: left;
        }
        .SeguirEnvio{
            display: flex;
            gap:20px;
            .btn{
                background: #1D4C5A 0% 0% no-repeat padding-box;
                width: 129px;
                height: 31px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .inactivo{
                cursor:auto;
                background: #a7a7a7 0% 0% no-repeat padding-box;
            }
            .Cursor{
                cursor: pointer;
            }
            .statusPedido{
                display: flex;
                color: var(--btn-color);
                font-size: 18px;
                font-weight: 500;

                .cancelado{
                    
                    color:brown;
                    
                }
            }
        }
    }

    .infoPedido{

        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap:30px;
        text-align: left;
        font: normal normal 300 15px/18px Helvetica;
        letter-spacing: 0px;
        color: #232323;
        
        h1{
            text-align: left;
            font: normal normal bold 15px/18px Raleway;
            letter-spacing: 0px;
            color: #232323;
        }

        .direccionEntrega{


        }
        .FechaMetodo{
            .fecha{

            }
            .metodoPago{

            }
        }
        
        .Totales{
            .resumenPedido{

            }

            .totalPedido{

            }
        }
    }

    .titleprod{
        margin-top: 50px;
        h1{
            text-align: left;
            font: normal normal bold 15px/18px Raleway;
            letter-spacing: 0px;
            color: #232323;
        }
    }
    .productos
    {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .ProductImages{
            display: flex;
            gap:10px;
            height: 100px;

            .itemPedido{
                display: grid;
                .items{

                    height: 80px;
                    align-items: center;
                    gap:20px;
                    .titleProdPedido{

                    }
                }
            }
            .imagenPedido{
                display: grid;
                width: 60px;
                height: 60px;
                background-color: #4198b4;
                gap:20px;
                img{
                    width: 60px;
                    height: 60px;
                }
            }
        }

    }
    
}


@media only screen and (min-width: 900px) and (max-width: 1100px){
}
@media only screen and (min-width: 320px) and (max-width: 900px) {
    .contentDetallePedido{
        display: inline;
        justify-content: center;
        padding: 0px;
        

        .Pedido{
            display: grid;
        }

        .Resumen{
            padding:0px;
            width: 90%;

            .ItemResumen
            .contentProductos
            .productos{
                .headersProd {
                    display: flex;
                }
                .itemproducto{
                    display: flex;
                }
                
            }
        }
    
        .infoPedido{
    
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap:30px;
            text-align: left;
            font: normal normal 300 15px/18px Helvetica;
            letter-spacing: 0px;
            color: #232323;
        }

        .productos
        {

            
            display: flex;

            .ProductImages{

                height: auto;
            }
    
        }
    }
    
}
@media only screen and (max-width: 320px) {
    .contentDetallePedido{
        display: inline;
        justify-content: center;
        padding: 0px;
        
        .Resumen{
            padding:0px;
            width: 90%;

            .ItemResumen
            .contentProductos
            .productos{
                .headersProd {
                    display: flex;
                }
                .itemproducto{
                    display: flex;
                }
                
            }
        }

        .Pedido{
            display: grid;
        }
    
        .infoPedido{
    
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap:30px;
            text-align: left;
            font: normal normal 300 15px/18px Helvetica;
            letter-spacing: 0px;
            color: #232323;
        }

        .productos
        {
            display: flex;

            .ProductImages{

                height: auto;
            }
    
        }
    }
}
