.wrapperOfertas
{
    justify-content: center;
    opacity: 1;
    display: flex;
    margin-bottom:150px;


    
.contentOfertas{
    
    justify-content: center;
    width: 90%; 
    max-width: 1620px;
    
    .contentOfertasTop
    { 
       
        align-items: center;
        margin-bottom: 20px;
        background-color: var(--btn-color);
        color: #ffffff;
        margin-bottom: 120px;
        margin-top: 60px;


        .itemOferta
        {
            display: flex;
            height: 406px;
            flex-direction: row;
            position: relative;

            .imagen{
                width: 50%;
                background: #f2f2f2;
                img{
                    width: 100%; 
                    height: 406px;
                }
                img:hover{
                    width: 100%;
                    opacity: 0.7;
                 }
            }

            .contentTexto{
                width: 50%; 

                .textoOferta{
                    padding-left:50px;
                    padding-top:40px;
                    padding-right: 60px;
                    display: grid;
                    gap:10px;
                    
                    .titulo{
                        font: normal normal normal 28px/33px Raleway;
                    }
                    .descuento{
                        font: normal normal bold 38px/42px Helvetica;
                    }
                    .descripcion{
                        font: normal normal normal 22px/26px Raleway;
                        text-decoration:line-through;
                        color:#989797;
                    }
                    .botones{
                        width: 210px;
                        height: 50px;
                        border: 2px solid #FFFFFF;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px;
                        display: flex;

                        .icon{
                            padding-left: 10px;
                            font: normal normal normal 20px/22px Helvetica;
                        }

                        .Link:hover{
                            text-decoration: none;
                        }
                        
                        .btn{
                            border: none;
                            color: white;
                            text-decoration: none;
                            cursor: pointer;       
                            background-color: var(--btn-color);
                            font: normal normal normal 21px/22px Helvetica;
                        }
                        
                    }
                }
            }

        }

        .itemOferta-reverse
        {
        flex-direction: row-reverse;
        }

        

    }
    .contentOfertasBottom
    {
        display: flex;
        width: 100%;
        gap:20px;
        justify-content: space-between;
        
        .itemOferta{

            position:relative;
            display: flex;
            margin-bottom:70px;

            .imagen{
                img{
                    max-width: 100%;
                    width: 451px;
                    height: 423px;
                }
            }
            .descuento{
                position:absolute;
                left: 53%;
                z-index:10;
                background-color: var(--btn-color);
                color: #FFFFFF;
                align-items: center;
                padding-left: 30px;
                padding-right: 30px;
                
                h1{
                    font: normal normal bold 56px/48px Helvetica;
                    margin-bottom: 1px;
                }
                h2{
                    font: normal normal bold 27px/48px Helvetica;
                    margin-top: 1px;
                }
            }

            .botones{
                background-color: var(--btn-color);
                color:#FFFFFF;
                
                justify-content: center;
                align-items: center;
                z-index: 10;
                margin-top: 435px;
                background-color: var(--btn-color);
                position:absolute;
                display: flex;

                .icon{
                    padding-left: 10px;
                }
                .Link:hover{
                    text-decoration: none;
                }
                .btn{
                    cursor: pointer;       
                    border: none;
                    color: white;
                    text-decoration: none;
                    padding: 1px 15px 1px 15px;
                    align-items: center;
                    display: flex;

                    h1{font: normal normal normal 18px/22px Helvetica;}
                }
            }
        }
    }
}

    @media only screen and (min-width: 900px) and (max-width: 1100px){

    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        .contentOfertas{
    
            width: 100%; 
            
            .contentOfertasTop
            { 
               
                align-items: center;
                margin-bottom: 20px;
                background-color: var(--btn-color);
                color: #ffffff;
                margin-bottom: 120px;
                margin-top: 60px;
        
        
                .itemOferta
                {
                    display: inline;

                    .imagen{
                        width: 100%;
                        img{
                            height: 320px;
                        }
                    }
        
                    .contentTexto{
                        width: 100%; 
                        height: 300px;
                    }
                    
                }
                .itemOferta-reverse
                {
                flex-direction: row;
                }

            }
            .contentOfertasBottom
                {
                    display: none;
                }
        }
    }
    @media only screen and (max-width: 320px) {

    }   
}