.contentRegister
{
    width: 521px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 24px 54px #0000000D;
    background: #F7F7F7;
    margin-bottom:50px;
    padding: 30px;
    .Link{
            text-decoration: underline;
        }

    .titulo{
        font: normal normal bold 40px/39px Helvetica;
        letter-spacing: 0px;
        color: #1D4C5A;
        text-align: center;
    }
    .form{
        
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:10px;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap:10px;
            width: 100%;
            
            .FormRow{
                display: flex;
                flex-direction: column;
                width: 100%;
                
                input{
                    width: 100%;
                    height: 50px;
                    font-size: 18px;
                    border: 1px solid #A7A7A7;
                }
                label{
                    margin-bottom: 5px;
                }
            }
        }
        button{
            color:white;
            display: flex;
            background-color: var(--btn-color);
            justify-content: center;
            align-items: center;
            border: 1px solid var(--btn-color);
            width: 298px;
            height: 60px;
            padding: 10px;
            cursor: pointer;
            text-decoration: none;
            font: normal normal normal 18px/22px Helvetica;
            margin-top:30px;
        }
    }

    .optionsCheck{
        margin-top:10px;
        width: 100%;
        display: grid;
        gap:10px;
    }
    @media only screen and (max-width: 345px) {
        width: 100%;
        
    .form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:10px;

        .row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:10px;
            width: 100%;
            
            .FormRow{
                display: flex;
                flex-direction: column;
                width: 100%;
                
                input{
                    width: 100%;
                    height: 40px;
                    font-size: 18px;
                    border: 1px solid #A7A7A7;
                }
                label{
                    margin-bottom: 5px;
                }
            }
        }
        button{
            color:white;
            display: flex;
            background-color: var(--btn-color);
            justify-content: center;
            align-items: center;
            border: 1px solid var(--btn-color);
            width: 100%;
            height: 60px;
            padding: 10px;
            cursor: pointer;
            text-decoration: none;
            font: normal normal normal 18px/22px Helvetica;
            margin-top:30px;
        }
    }
    }
}

.modal-Register{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
.mejerror{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    font: normal normal 500 16px/18px Helvetica;

    .icon{
        color: rgb(210, 67, 67);
    }
    .txt{
        padding:0px;
        background-color: #FFFFFF;
        border-radius: 25px;
        color: rgb(210, 67, 67);
    }
}
   
}