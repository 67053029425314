.wrapperSlides{

    margin-top: 10px ;
    //background-color: rgb(241, 29, 29); 
    display: flex;
    justify-content: center;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    

    .contenedorSlide    
    {
        width: 95%;   
        display: flex;
        justify-content: flex-end;
        max-width: 1620px;
        position: relative;
    }
    .Texto-Cuadro{
        z-index: 1;
        height: auto;
        background-color: #ffffff;
        width: 45%;
        position: absolute; /* Posicionamiento absoluto con respecto a contenedorSlide */
        left: 10%; /* Alinea a la izquierda */
        top: 12%; /* Alinea arriba */
        padding-bottom:20px;
        justify-content: center;
        display: flex;
        

        .texto{
            display: grid;
            gap:5px;

            
            width: 80%;

            .titulo1{
                text-align: left;
                color:#222322;

                    h1{
                        font-size: 55px;
                        margin-bottom: -40px;
                        position:relative;
                        display: inline-block;
                    }

                    h1::after{
                        content: '';
                        position: absolute;
                        width: 90px;
                        height: 3px;
                        background-color: var(--btn-color);
                        top:40px;
                        left: 190px;
                    } 
            }

            .titulo2
            {
                color:#222322;
                h1{
                    font-size: 55px;
                    margin-top: -5px;
                    position:relative;
                    display: inline-block;
                }
            }

            .textoInfo{
                text-align: left;
                color: gray;
                width: 100%;
                
                span{
                    font-size: 18px;
                }
            }

            .botones{

                margin-top: 40px;
                align-items: center;
                display: flex;
                .Link:hover{
                    text-decoration: none;
                }

                text-align: left;
                font-size: 12px;

                .btn{
                    border: none;
                    color: white;
                    padding: 8px 15px;
                    text-decoration: none;
                    margin: 4px 2px;
                    cursor: pointer;       
                    background-color: var(--btn-color);
                }

                .btn2{
                    border: solid 2px;
                    border-color: var(--btn-color);
                    text-decoration: none;
                    padding: 6px 14px;
                    margin: 3px 1px;
                    color: var(--btn-color);
                }

                .iconCat{
                    font-size: 12px;
                }
            }   
        }
    }
    
    .slideInicio{
        max-width:50%;  
        .slider{
        width: 100%;

        .slick-track
        {
            width: 500px;
        }
        img{
            overflow: hidden;
            height: 500px;
        }


        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){

    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {

        .contenedorSlide{
            transition: 1s;
            width: 100%;
            position: relative;

        }

        .Texto-Cuadro{
            width: 95%;
            position: absolute;
            margin-top: 205px;
            left: 2.5%; /* Alinea a la izquierda */
            top: 0%; /* Alinea arriba */
            padding:0px;

            .texto{
                justify-content: center;
                margin: 10px 15px 10px 15px ;
            

            .titulo1{
                width: 50px;
                h1{
                    font-size: 35px;
                }
                h1::after{
                    top:25px;
                    left: 120px;
                } 
            }

            .titulo2{
                h1{font-size: 35px;}
            }

            .textoInfo{                    
                span{font-size: 14px;}
            }

            .botones{margin-top: 30px;display: flex; justify-content: center;}
        }
            
        }
        .slideInicio{
            max-width:100%; 
            position: relative;
            height: 100%;
            .slider{
                width: 100%;                    
                img{
                    transition: 1s;
                    overflow: hidden;
                    height: 500px;
                    width: 100%;
                }
            }
        }
        
        .WrapperMid{
            justify-content: center;
            .ContenedorMid 
            .mid {
            display: inline;
            justify-content: center;
            .itemMid .logo img {
            height: 60px;
            transition: 1s;
        }}}
    }
    @media only screen and (max-width: 320px) {
    }

}