.wrapperSeccion5
{
    justify-content: center;
    background: #F7F7F7;
    opacity: 1;
    margin-top : 30px;

    .contenedorSn5
    { 
        align-items: center;
        display: flex;
        justify-content: space-between;

        .contactoLeft
        {
            
            height: 528px;
            opacity: 1;
            width: 100%;

            .ImgContacto{
                img{
                    height: 528px;
                    width: 100%;
                }
            }

            .direccionmaps
            {
                
            }
        }

        .contactoRight{
            width: 100%;
            height: 528px;
            opacity: 1;
            justify-content: center;
            text-align: center;
            gap: 150px;

            .textoContacto{
                height: 328px;
                align-items: center;
                text-align: center;
                margin-top: 80px;

                h1{
                    text-align: center;
                    font: normal normal bold 30px/38px Helvetica;
                    letter-spacing: 0.5px;
                    color: #000000;
                    opacity: 1;

                }
                h3{
                    font: normal normal medium 22px/26px Raleway;
                    letter-spacing: 0px;
                    color: #4198B4;
                    text-transform: uppercase;
                    opacity: 1;
                }
                span{
                    text-align: center;
                    font: normal normal normal 18px/25px Raleway;
                    letter-spacing: 0.71px;
                    color: #000000;
                    opacity: 1;

                }

                .boton
                {
                    margin-top: 50px;
                }

                .iconCat
                {
                    
                    background: #1D4C5A 0% 0% no-repeat padding-box;
                    opacity: 1;
                    width: 50px;
                    color:white;
                    padding: 3px 3px 3px 3px;
                }
            }
        }
    }

    @media only screen and (min-width: 380px) and (max-width: 1000px) {
        margin-top : 0px;
        margin: 5px;
        .contenedorSn5
        { 
            display: flex;
            flex-direction: column;

            .contactoLeft{
                order:2;
                height: auto;
                .ImgContacto{
                    img{
                        height: 350px;}
                    }
            }
            .contactoRight{
                order:1;
                height: auto;
                    .textoContacto{
                        height:auto;

                        .boton{margin-bottom:5px;}
        
                        h1{
                            font: normal normal bold 25px/38px Helvetica;
                        }
                        h3{
                            font: normal normal bold 30px/26px Raleway;
                        }
                        span{
                            font: normal normal normal 15px/25px Raleway;

        
                        }
                    }
        
            }
        }


    }
    @media only screen and (max-width: 380px) {
        margin-top : 0px;
        margin: 5px;
        .contenedorSn5
        { 
            display: flex;
            flex-direction: column;

            .contactoLeft{
                order:2;
                height: auto;
                .ImgContacto{
                    img{
                        height: 300px;}
                    }
            }
            .contactoRight{
                order:1;
                height: auto;
                    .textoContacto{
                        height:auto;

                        .boton{margin-bottom:5px;}
                        h1{
                            font: normal normal bold 22px/38px Helvetica;
                        }
                        h3{
                            font: normal normal bold 25px/26px Raleway;
                        }
                        span{
                            font: normal normal normal 15px/25px Raleway;

        
                        }
                    }
        
            }
        }
    }
}