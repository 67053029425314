.wrapperNosotros
{
    margin-top: 50px;

    .contenedorNosotros
    { 
        display: flex;    
        align-items: center;
        transition: 1s; 
        justify-content: center;
        
        .nosotrosContent{
            display: flex;
            margin-top: 10px;
            margin-bottom:30px;
            width: 95%;
            max-width: 1620px;
        
            .NosotrosLeft{

                margin: 0px 30px 0px 0px;
                width: 60%;

                
                .contentTxt{
                    text-align: left;
                    
                    h1{
                        font: var(--Font-h1);
                        letter-spacing: 0.23px;
                        color: #232323;
                        opacity: 1;
                        margin:0px;
                                      
                    }
                    span{
                        margin-top:5px;
                        font: var(--font-span);
                        letter-spacing: 0.5px;
                        line-height: 1.2;
                        color: #232323;
                    }
                }
            }

            .NosotrosImg{

                .imagen{
                    opacity: 1;

                    img{
                        width: 100%;
                        height: 548px;
                    }
                }
            }
        
        }
    }

    .ContenedorFeaturesNosotros
    {
        display: flex;
        background: #1D4C5A 0% 0% no-repeat padding-box;
        align-items: center;
        height: 461px;
        opacity: 1;
        justify-content: center;

        .featuresNosotros{
            display: flex;
            color:white;
            align-items: center;
            width: 95%;
            max-width: 1620px;
            
            .features01
            {
                width: 500px;
                margin-right: 30px;

                .contentTxt{
                    text-align: left;
                    
                    h1{
                        font: var(--Font-h1);
                        letter-spacing: 0.23px;
                        color:white;
                        opacity: 1;
                        margin:0px;
                                      
                    }
                    span{
                        margin-top:5px;
                        font: var(--font-span);
                        letter-spacing: 0.5px;
                        line-height: 1.2;
                        color:white;
                    }
                }
            }
            .features02
            {
                flex:1;
                justify-content: center;
                align-items: center;

                .ContentItems
                {
                    .Items
                    {
                        justify-content: center;
                        margin-bottom:20px;

                        .ItemTexto{
                            justify-content: center;
                            align-items: center;
                            display:flex;
                            height: 35px;
                            
                            .check{
                                margin-right: 10px;
                                background: #4198B4 0% 0% no-repeat padding-box;
                                width:35px;
                                height: 100%;
                                
                            }
                            .texto{
                                width:250px;
                                justify-content: left;
                            }
                        }
                        
                    }
                 }
            }
            
        }
    }

    @media only screen and (max-width: 900px) {
        .contenedorNosotros 
        {
            
            .nosotrosContent{
                transition: 1s;
                display: grid;
                gap:20px;   
                width: 100%;  
                height: 100%;
                margin-right: 5px;
                margin-left: 5px;

                .NosotrosLeft{
                    width: 100%;
                    .contentTxt{
                        h1{font: var(--font-h1-min);}
                        span{font: var(--font-span-min);}
                    }
                }
                .NosotrosImg{
                    .imagen{
                        img{
                            width: 100%;
                            height: 348px;
                        }
                    }
                }
            }
        }
        .ContenedorFeaturesNosotros{
            height: 100%;
            justify-content: left;
            
            
            .featuresNosotros{
                display: inline;
                margin-top:40px;
                .features01
                {
                    margin-right: 5px;
                    margin-left: 5px;
                    width: 100%;
                    .contentTxt{
                        margin-bottom:40px;
                        h1{
                            font: var(--font-h1-min);
                            margin-bottom:5px;
                        }
                        span{
                            margin-top:5px;
                            font: var(--font-span-min);
                        }
                    }
                }
                .features02
                {
                    justify-content: left;
                    align-items: left;
                    width: 100%;
                    margin-right: 5px;
                    margin-left: 5px;
                    .ContentItems
                    {
                        text-align: left;
    
                        .Items
                        {
                            justify-content: left;
                            margin-bottom:20px;

                            .ItemTexto{
                                justify-content: left;
                            }
                        }
                    }
                    
                }
            }
        }
    }
   
}