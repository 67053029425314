.wrapperFaqs{
    justify-content: center;
    display: flex;
    opacity: 1;
    margin-bottom:150px;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    html {
     scroll-behavior: smooth;
    }
    .contentFaqs{
        width: 90%;
        max-width: 1620px;
        margin-top: 40px;

        .title{
            font: normal normal bold 40px/48px Helvetica;
            letter-spacing: 0.5px;
            color: #222322;

            .icon{
                font-size: 35px;
            }
        }

        .infoFaqs
        {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-content: center;
            gap:20px;
            .cardFaqs{
                gap:10px;
                margin-top:30px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                opacity: 1;
                margin-bottom:10px;
                //cursor: pointer;
                font: normal normal normal 15px/19px Helvetica;
                letter-spacing: 0px;
                color: #5a5a5a;
                padding: 10px;
                //align-items: center;
                height: 200px;
                .Faq{
                    display: grid;
                    grid-template-rows:repeat(3, 1fr);
                    height: 100%;
                    .titleCard{
                        font: normal normal 500 19px/25px Helvetica;
                        display: flex;
                        align-items: center;
                        color: #222322;
                    }
                    .txt{}
                    .readmore{
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        .txtreadm{
                            background-color: var(--btn-color);
                            padding:10px;
                            cursor: pointer;
                            color: #ffffff;
                        }
                    }
                }
            }
            .cardFaqs.color{
                background: #f1f1f1 0% 0% no-repeat padding-box;
            }
        }

        .detalleFaqs
        {
            display: flex;
            margin: auto;
            margin-top: 20px;
            width: 700px;
            
            .ContentReadmore{
                background: #f1f1f1 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                padding: 20px;
                padding-bottom: 50px;
                width: 100%;
                display: grid;
                gap:20px;
                color: #535353;

                .return{
                    margin-bottom: 10px;
                }
                .titleReadMore{
                    font: normal normal 500 20px/22px Helvetica;
                    color:#222322
                }
                .answer{
                    font: normal normal normal 15px/21px Helvetica;
                    color: #535353;
                    margin-left: 10px;
                }
            }
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
        .contentFaqs{
            .infoFaqs
            {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        
    }

    @media only screen and (min-width: 345px) and (max-width: 900px){
        .contentFaqs{
            width: 100%;
            padding:10px;
            .infoFaqs
            {
                grid-template-columns: repeat(1, 1fr);
            }
            .title{
                font: normal normal bold 24px/20px Helvetica;
            }
            .detalleFaqs
            {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 345px) {
        .contentFaqs{
            width: 100%;
            padding:10px;
            .infoFaqs{
                grid-template-columns: repeat(1, 1fr);
                .cardFaqs{
                   
                    font: normal normal normal 14px/19px Helvetica;
                    
                    .Faq{
                        .titleCard{
                            font: normal normal 500 17px/25px Helvetica;
                        }
                    }
                }
            }
            .title{
                font: normal normal bold 20px/20px Helvetica;
            }
            .detalleFaqs
            {
                width: 100%;

                .ContentReadmore{
                    color: #535353;

                    .titleReadMore{
                        font: normal normal bold 17px/20px Helvetica;
                    }
                    .answer{
                        font: normal normal normal 15px/20px Helvetica;
                        color: #535353;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

}