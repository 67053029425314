.WrapperAccount{
    justify-content: center;
    display: flex;
    margin-bottom:80px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    opacity: 1;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    .contentPerfil{
        display: flex;
        width: 90%; 
        max-width: 1620px;
        height: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 10px 24px 54px #0000000D;
        opacity: 1;

        .left{
            width: 30%;
            background-color: var(--btn-color);;
            color:#ffffff;
            padding-top:60px;
            padding-left: 40px;
            height: 882px;

            .MenuMovPerfil{display: none;}

            .MenuPerfil{
                display: inline;
                

                .titPerfil{
                    font: normal normal bold 24px/29px Helvetica;
                    letter-spacing: 0.5px;
                    color: #FFFFFF;
                    margin-bottom: 40px;
                }
            
                .menu {
                    ul {
                        list-style: none;
                        padding: 0;
                    }
                    li {
                        margin-bottom: 30px;
                        align-items: center;
                        cursor: pointer;

                        .icon{
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }

                .BtnOut{
                    margin-top: 60px;
                    display: flex;
                    .icon{
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .right{
            width: 100%;
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        
        .contentPerfil{
            display: inline;
            width: 100%;
            margin-top: 10px;

            .left{
                display: flex;
                width: auto;
                padding: 20px;
                height: auto;

                .MenuPerfil{display: none;}

                .MenuMovPerfil
                {
                    .titPerfil{
                        font: normal normal bold 24px/29px Helvetica;
                        color: #FFFFFF;
                    }
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .midMobile{
                        
                    }
                }
                
            }
            .right{
                width: 100%;

            }

        }
        
    }
    @media only screen and (max-width: 320px) {
        .contentPerfil{
            display: inline;
            width: 100%;
            margin-top: 10px;

            .left{
                display: flex;
                width: auto;
                padding: 20px;
                height: auto;

                .MenuPerfil{display: none;}

                .MenuMovPerfil
                {
                    .titPerfil{
                        font: normal normal bold 24px/29px Helvetica;
                        color: #FFFFFF;
                    }
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .midMobile{
                        
                    }
                }
                
            }
            .right{
                width: 100%;

            }

        }
    }
}