.mobile-menu {
    position: relative;
  }
  
  .menu-button {
    background-color: #ffffff;
    color: #000000;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }

  .menu-close .menu-list {
    display: none; /* Cambiamos el valor para abrir hacia la izquierda */
  } 
  
  .menu-list {
    z-index: 1;
    transition: all 0.7s ease-in-out;
    position: absolute;
    left: -206px; /* Cambiamos el valor para abrir hacia la izquierda */
    width: 250px; /* Cambiamos el tamaño del contenedor */
    background-color: #fff;
    color: #000000;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

    .Link{
      display: flex;
      gap: 10px;
      color: var(--btn-color);;
    }
  }
  
  .menu-list li {
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .Link {
    text-decoration: none;
    color: #333;
  }
  
  .menu-list {
    display: block;
  }
  