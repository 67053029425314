.WrapperPay{
    justify-content: center;
    opacity: 1;
    display: flex;
    margin-bottom:150px;
    margin-top:30px;
    background: #F7F7F7 0% 0% no-repeat padding-box;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    .contentPay
    {
        width: 90%; 
        max-width: 1620px;

        .Resumen{
            display: grid;
            width: 70%;
            gap:50px;
            justify-content: center;
            margin: auto;
            background-color:aliceblue;
            padding: 50px;

            .IdPedido{
                font-size: 30px;
                font-weight: bold;
                color:var(--btn-color);
            }
            
            .ItemResumen{

                .title{
                    font-size: 20px;
                }

                .info{

                }

                .contentProductos
                {
                    width: 100%;
                    .TitleOrden{display: none;}
                    .productos-movil
                        {
                            display: none;
                        }

                    .productos{
                        width: 100%;
                        display: table;
                        border-collapse: collapse;
                        align-items: center;

                        .headersProd{
                            display: table-row;
                            font: normal normal normal 18px/21px Raleway;
                            letter-spacing: 0px;
                            color: #1D4C5A;
                            

                            h1{
                                font-size: 14px;
                            }
                        }
                        .headersProd > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:40px;
                            
                        }
                        
                        .itemproducto{
                            display: table-row;
                            

                            .cell{  
                                vertical-align: middle;

                                .imagen{
                                    display: flex;
                                    align-items: center;
                                    gap:50px;

                                    img{
                                        width: 50px;
                                        height: 50px;
                                    }
                                    .titleProd{
                                        font: normal normal normal 18px Helvetica;
                                        letter-spacing: 0px;
                                        color: #232323;
                                    }
                                }
                                    
                                .cantidadContent{
                                    display: flex;
                                    align-items: center;
                                    gap:20px;

                                    .btn{
                                        border: none;
                                        color: white;
                                        text-decoration: none;
                                        cursor: pointer;      
                                    }

                                    .LabelCantidad{
                                        display: none;
                                    }

                                    .contentBtn{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: var(--btn-color);
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    .contentBtnDes{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: #dcdcdc;
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    h1{
                                        font: normal normal medium 18px/21px Raleway;
                                    }
                                    .contador{
                                        text-align: left;
                                        font: normal normal bold 17px/29px Helvetica;
                                        letter-spacing: 0px;
                                        color: #4198B4;
                                        opacity: 1;
                                    }
                                }
                                
                                .precio{
                                    color: #A7A7A7;
                                    display: flex;
                                    .Oferta{
                                        color: red; 
                                        font-size: 11px;
                                        margin:0;
                                    }
                                }
                                .total{
                                    color: #4198B4;

                                }
                                .delete{
                                    cursor: pointer;

                                }
                            }
                        }

                        .itemproducto > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:20px;
                        }
                    }
                    
                }
                .totalPrecio
                {
                    width: 300px;
                    float: right;
                    margin: 0;
                    display: grid;
                    gap:20px;
                    margin-top:20px;
                    font: normal normal normal 16px/20px Helvetica;

                    .TotalEnvio{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        font: normal normal normal 14px/20px Helvetica;
                        }
                        .precio{ 
                            
                            color: #4198B4;
                        }
                        .bold{
                            font-weight: bold;
                        }
                        .letra {
                            
                        }
                    }
                    
                }
            }
        }

        .rutaPay{
            font: normal normal medium 18px/21px Raleway;
            letter-spacing: 0px;
            color: #CDCFD1;
            opacity: 1;
            display: flex;
            
            img{
                width: 54px;
                height: 47px;
            }
            .t2
            {
                font: normal normal medium 18px/21px Raleway;
                letter-spacing: 0px;
                color: #4198B4;
                opacity: 1;
            }
        }
        .titulo{
            font: normal normal bold 38px/46px Helvetica;
            letter-spacing: 0px;
            color: #232323;
        }
        .rutaPago{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font: normal normal normal 18px/25px Helvetica;
            letter-spacing: 0px;
            color: #787a7a;
            align-items: center;

            .Active {
                color: #4198B4;
            }

            .itemRutaPago{
                display: flex;
                align-items: center;
                gap:10px;
                
                .circle {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .circle span {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }

                .circleActive {
                    background-color: #1D4C5A;
                    color: white;
                }

            }
        }
        .contentPaySeguimiento{
            justify-content: center;
            display: flex;
            .seguimiento{ 
                text-align: center;
                .titlePago{
                    font: normal normal bold 68px/82px Helvetica;
                    color:var(--btn-color);
                }
                .TxtSeg{
                    font-size: 20px;
                    color: #787a7a;
                   
                    .idseg{
                        font-size: 50px;
                        font-weight: 500;
                        justify-content: center;
                        color:var(--btn-color);
                        display: flex;
                    }
                }
                .spinner{
                        justify-content: center;
                        display: flex;
                }
            }
        }

        .Acciones{
            display: grid;
            gap:10px;
            justify-content: center;
            margin-top: 10px;

            .item{
                border: none;
                width: auto;
                text-decoration: none;
                cursor: pointer;    
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--btn-color);
                padding: 10px;
                color: white;  
                float: right;          

                    .txt{
                        font-size: 15px;
                    }
            }
            
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        width: 100%;
        margin-bottom:0px;
        .contentPay
        {
            width: 100%; 
            max-width: 1620px;

            .Resumen
            {
                
                .ItemResumen
                .contentProductos
                .productos
                {   
                    display: grid;
                }
            }
            


            .rutaPago{
                justify-content: left;
                margin-bottom: 20px;
                .InactiveMobile{
                    display: none;
                }
            }
            
        }
    }
    @media only screen and (max-width: 320px) {
        width: 100%;
        margin-bottom:0px;
        .contentPay
        {
            width: 100%; 
            max-width: 1620px;

            .Resumen
            {
                
                .ItemResumen
                .contentProductos
                .productos
                {   
                    display: grid;
                }
            }
            .rutaPago{
                justify-content: left;
                margin-bottom: 20px;
                .InactiveMobile{
                    display: none;
                }
            }
            
        }
    }
}