.wrapperTyc
{
    justify-content: center;
    opacity: 1;
    display: flex;
    margin-bottom:150px;

    
    .contentTyc{
        
        justify-content: center;
        width: 90%; 
        max-width: 1620px;
        display: grid;
        gap:20px;
        margin-top: 10px;
        color: #232323;

        .title{
            font: normal normal 500 25px/18px Helvetica;
        }
        .contetntxt
        {
            text-align: justify;
        }
    }
}