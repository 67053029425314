.cart
{
    position: fixed;
    width: 300px;
    top: 0;
    right: -350px; 
    z-index: 999;
    height: 100%;
    transition: right 0.3s ease;
    background-color: white;
    padding: 15px;
    background-color: #fcfcfc;
    box-shadow: 6px 6px 27px -7px rgba(120, 120, 120, 0.94);
    -webkit-box-shadow: 6px 6px 27px -7px rgba(120, 120, 120, 0.94);
    -moz-box-shadow: 6px 6px 27px -7px rgba(120, 120, 120, 0.94);

    .infoCart{
        height: 100%;

        .topcart{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .title{
                color: var(--btn-color);
                font: normal normal bold 22px Raleway;
            }

            .cerrar{
                display: flex;
                justify-content: right;
                cursor: pointer;
                .icon{
                    font-size: 19px;
                }
            }
        }

        .cartItems{
            width: 100%;
            height: 100%;
            display: grid;
            margin-top:10px;
            grid-template-rows: .8fr;

            .itemCart{
                height: 100%;
                overflow-y: auto;
                .imgDetails{
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    margin-bottom: 10px;
                    //justify-content: space-between;

                    img{
                        width: 60px;
                        height: 70px;
                        object-fit: cover;
                    }

                    .details{
                        display: grid;
                        
                        .titleProd{
                            font-size: 18px;
                            color: var(--btn-color);
                        }
                        .price{
                            font-size: 13px;
                            color: black;
                        }
                        .Oferta{color: red; font-size: 12;}
                        .delete{
                            text-align: right;
                            .del{
                                color:red;
                                cursor:pointer;
                            }
                        }
                    }
                }
            }
            .contentBottom{
                display: grid;
                width: 100%;
                -webkit-box-shadow: -2px -10px 23px -22px rgba(0,0,0,0.47);
                -moz-box-shadow: -2px -10px 23px -22px rgba(0,0,0,0.47);
                box-shadow: -2px -10px 23px -22px rgba(0,0,0,0.47);
                .itemCartBottom{
                    display: grid;
                    .Total{
                        font-size: 18px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        margin-bottom: 10px;
                        font-weight: 500;
                        color:black;
                        span{
                            margin-right: 20px;
                        }
                    }
                    .pago{
                        margin-bottom:10%;
                        .botonCompra{
                            color:white;
                            display: flex;
                            margin-top: 5px;
                            background-color: var(--btn-color);
                            justify-content: center;
                            align-items: center;
                            border: none;
                            padding: 15px;
                            cursor: pointer;
                            text-decoration: none;
                            font-size: 18px;
                        }
                        .reset{
                            color: red;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.cart-open .cart{
    right: 0;
  } 

  @media only screen and (min-width: 900px) and (max-width: 1100px) {}
  @media only screen and (max-width: 450px) {
    .cart
    {
        width: 100%;
        right: -120%; 
        padding: 0px;
        padding-top:5px;

        .infoCart{
            height: 100%;

            .topcart{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                align-items: center;
    
                .title{
                    color: var(--btn-color);
                    font: normal normal bold 22px Raleway;
                }
    
                .cerrar{
                    display: flex;
                    justify-content: right;
                    cursor: pointer;
                    .icon{
                        font-size: 30px;
                    }
                }
            }

            .cartItems{
                width: auto;
                height: 100%;
                display: grid;
                
        
                .itemCart{
                    margin: 0px 10px 0px 10px;
                    .imgDetails{
                        display: grid;
                        margin-bottom: 10px;
                        justify-content: center;
        
                        img{
                            width: 70px;
                            height: 80px;
                            object-fit: cover;
                        }
        
                        .details{
                            display: grid;
                            
                            .titleProd{
                                font-size: 18px;
                                color: var(--btn-color);
                            }
                            .price{
                                font-size: 13px;
                                color: black;
                            }
                            .Oferta{color: red; font-size: 12;}
                            .delete{
                                text-align: right;
                                .del{
                                    color:red;
                                    cursor:pointer;
                                }
                            }
                        }
                    }
                }
                .contentBottom{
                    display: grid;
                    width: 100%;
                    .itemCartBottom{
                        display: grid;
                        .Total{
                            font-size: 18px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 10px;
                            font-weight: 500;
                            color:black;
                            span{
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
            .title{
                font-size: 13px;
                color: var(--btn-color);
                margin:10px;
                margin-bottom: 10px;
            }

            .cerrar{
                .icon{
                    font-size: 25px;
                }
            }
        }
    }   
  }

  .contentModalExpi{
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    gap:15px;
    .button{
        display: flex;
        justify-content: right;
        .cerrar{
            background-color: var(--btn-color);
            color: #ffffff;
            padding: 10px;
            float: right;
            font-size: 18px;
            border: none;
            cursor: pointer;
        }
        .cerrar2 {
            background-color: #ffffff;
            color: var(--btn-color);
        }
    }
    .contador{
        font-size: 25px;
        font-weight: bold ;
    }

    .exp{
        font-size: 18px;
        font-weight: 500;
        justify-content: center;
        margin-top:20px;
    }
  }