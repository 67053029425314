.wrapperDelivery{
    justify-content: center;
    display: flex;
    margin-bottom:80px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    opacity: 1;


    .Link{
        text-decoration:none;
        color:inherit;
    }

    .contetnDelivery{
        width: 90%; 
        max-width: 1620px;
        height: 100%;

        .rutaOrden{
            font: normal normal medium 18px/21px Raleway;
            letter-spacing: 0px;
            color: #CDCFD1;
            opacity: 1;
            display: flex;
            
            img{
                width: 54px;
                height: 47px;
            }
            .t2
            {
                font: normal normal medium 18px/21px Raleway;
                letter-spacing: 0px;
                color: #4198B4;
                opacity: 1;
            }
        }
        .titulo{
            font: normal normal bold 38px/46px Helvetica;
            letter-spacing: 0px;
            color: #232323;
        }
        .rutaDelivery{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font: normal normal normal 18px/25px Helvetica;
            letter-spacing: 0px;
            color: #787a7a;
            align-items: center;

            .Active {
                color: #4198B4;
            }

            .itemRutaPago{
                display: flex;
                align-items: center;
                gap:10px;
                
                .circle {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .circle span {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }

                .circleActive {
                    background-color: #1D4C5A;
                    color: white;
                }


            }
        }

        .contentInfo{
            display: flex;
            justify-content: space-between;
            gap:20px;
            

            .left{
                width: 70%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 10px 24px 54px #0000000D;
                opacity: 1;
                padding: 30px;
                padding-top:20px;                
                max-height: 100%;
                
                .SeleccionMobile{display: none;}

                .title{
                    justify-content: center;
                    font: normal normal bold 18px/22px Helvetica;
                    letter-spacing: 0px;
                    color: #232323;

                    hr{
                        height: 1px;
                        background-color: #5797B1; 
                    }    
                    
                }

                .AddAddress{
                    display: flex;
                    justify-content: right;
                    font-size: 14px;
                    margin-bottom:20px;

                    .txtlink{
                        color:var(--btn-color);
                        text-decoration: underline;
                        font: normal normal bold 16px Helvetica;
                        letter-spacing: 0px;
                    }
                }

                .checkFacturas{
                    font-size: 15px;
                }
                
                .direcciones{
                    display: grid;
                    .cardAddress{

                        gap:10px;
                        margin-top:20px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        opacity: 1;
                        margin-bottom:10px;
                        cursor: pointer;
                        font: normal normal normal 16px/19px Helvetica;
                        letter-spacing: 0px;
                        color: #232323;
                        padding-left: 10px;
                        display: flex;
                        align-items: center;
                        height: 86px;


                        .iconCard{
                            margin-right: 5px;
                            color:  var(--btn-color);
                        }
                        .dir{
                            gap:50px;
                            .item{
                                margin-top:5px;

                                h1{font: normal normal bold 18px/21px Raleway;}
                            }
                        }
                        
                    }
                    .cardAddress.selected{
                        border-left: 15px solid #4198b4;
                        background: #F2F2F2 0% 0% no-repeat padding-box;
                        border-left: 15px solid #4198b4;
                        color:  var(--btn-color);
                        opacity: 1;
                        font-size: 15px;
                        
                    }
                }
            }
            .right
            {
                width: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 10px 24px 54px #0000000D;
                opacity: 1;
                padding: 30px;
                padding-top:20px;                
                max-height: 100%;
                

                .title{
                    justify-content: center;
                    font: normal normal bold 18px/22px Helvetica;
                    letter-spacing: 0px;
                    color: #232323;  
                    hr{
                        height: 1px;
                        background-color: #5797B1; 
                    }     
                }
                .SeleccionMetodoMobile{display: none;}

                .FormaDePago{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-column-gap:10px;
                    margin-bottom: 10px;
                    width: 50%;
                    
                        .btn{
                            border: none;
                            text-decoration: none;
                            cursor: pointer;       
                            
                            
                            .btnContent{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: white;
                                padding: 15px 15px;
                                color: var(--btn-color);
                                border: 1px solid var(--btn-color);

                                .txt{
                                    font-size: 20px;
                                }
                                
                                .imgn{
                                    color: var(--btn-color);
                                    width: 54px;
                                    height: 47px;
                                }

                                .Active{
                                    color: white;
                                }
                            }
                            .Active{
                                background-color: var(--btn-color);
                                color:white;
                            }
                        }
                }

                .contentProductos
                {
                    width: 100%;
                    box-shadow: 10px 24px 54px #0000000D;
                    .TitleOrden{display: none;}
                    .productos-movil
                        {
                            display: none;
                        }

                    .productos{
                        width: 100%;
                        display: table;
                        border-collapse: collapse;
                        align-items: center;

                        .headersProd{
                            display: table-row;
                            font: normal normal normal 18px/21px Raleway;
                            letter-spacing: 0px;
                            color: #1D4C5A;
                            

                            h1{
                                font-size: 14px;
                            }
                        }
                        .headersProd > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:40px;
                            
                        }
                        
                        .itemproducto{
                            display: table-row;
                            

                            .cell{  
                                vertical-align: middle;

                                .imagen{
                                    display: flex;
                                    align-items: center;
                                    gap:50px;

                                    img{
                                        width: 50px;
                                        height: 50px;
                                    }
                                    .titleProd{
                                        font: normal normal normal 18px Helvetica;
                                        letter-spacing: 0px;
                                        color: #232323;
                                    }
                                }
                                    
                                .cantidadContent{
                                    display: flex;
                                    align-items: center;
                                    gap:20px;

                                    .btn{
                                        border: none;
                                        color: white;
                                        text-decoration: none;
                                        cursor: pointer;      
                                    }

                                    .LabelCantidad{
                                        display: none;
                                    }

                                    .contentBtn{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: var(--btn-color);
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    .contentBtnDes{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: #dcdcdc;
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    h1{
                                        font: normal normal medium 18px/21px Raleway;
                                    }
                                    .contador{
                                        text-align: left;
                                        font: normal normal bold 17px/29px Helvetica;
                                        letter-spacing: 0px;
                                        color: #4198B4;
                                        opacity: 1;
                                    }
                                }
                                
                                .precio{
                                    color: #A7A7A7;
                                    display: flex;
                                    .Oferta{
                                        color: red; 
                                        font-size: 11px;
                                        margin:0;
                                    }
                                }
                                .total{
                                    color: #4198B4;

                                }
                                .delete{
                                    cursor: pointer;

                                }
                            }
                        }

                        .itemproducto > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:20px;
                        }
                    }
                    
                }

                .totalPrecio
                {
                    width: 400px;
                    float: right;
                    margin: 0;
                    display: grid;
                    gap:20px;
                    margin-top:20px;
                    
                    .subTotal{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font: normal normal normal 16px/43px Helvetica;
                        }
                        .precio{ 
                            font: normal normal normal 23px/43px Helvetica;
                            color: #4198B4;
                        }
                    }

                    .TotalEnvio{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font: normal normal normal 16px/43px Helvetica;
                        }
                        .precio{ 
                            font: normal normal normal 30px Helvetica;
                            color: #4198B4;
                        }
                        .letra {
                            font: normal normal normal 25px Helvetica
                        }
                    }

                    .TotalFull{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .totalName{
                            color: #232323;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                            font-weight: bold;
                        }
                        .totalprice{
                            font: normal normal normal 30px Helvetica;
                            letter-spacing: 0px;
                            color: #4198B4;
                            margin: 0;
                        }
                        .WaitShipping{
                            font-size: 15px;
                            color: #660003;
                        }
                    }

                    
                }

                .continuar{
                    width: 100%;
                    display: flex;
                    justify-content: right;

                    .button{
                        text-transform: uppercase;
                        color:white;
                        display: flex;
                        background-color: var(--btn-color);
                        justify-content: center;
                        align-items: center;
                        border: 1px solid var(--btn-color);
                        width: 150px;
                        height: 30px;
                        padding: 10px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 14px;
                        margin-top:30px;
                        float:right;
                    
                }
                }

                .MsjeError
                {
                    color: #660003;
                    float: left;
                }

                .msjEnvio{
                    font-size: 12px;
                    color: #660003;
                   
                }

            }
        }

    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 390px) and (max-width: 900px) {
        .contetnDelivery{
            width: 100%;
            margin:5px;
            

            .rutaDelivery{
                justify-content: left;
                margin-bottom: 20px;
            }

            .titulo{display: none;}

            .rutaDelivery{
                .InactiveMobile{
                    display: none;
                }
            }

            .contentInfo{
                display: flex;
                flex-direction: column;
                margin: 5px;
                gap:10px;

                .right{
                    width: 100%;
                    padding:0px;
                    padding-bottom: 30px;

                    .FormaDePago{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                        margin-bottom: 20px;
                        transition: opacity 0.8s ease;
                        opacity: 0;

                        .btn{
                            width: 100%;
                            .Active{
                                background-color:var (--btn-color);
                            }
                        }

                        .MetodoSeleccionado{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            display: grid;
                            gap:20px;
                            width: 100%;
                            box-shadow: 5px 5px 10px rgba(90, 90, 90, 0.3);
                            
                            
                            .titleMetodo{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsMetodo{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }


                        }
                    }

                    .contentProductos
                    {
                        padding-top: 20px;

                        .TitleOrden{
                            display: flex;
                            font-size: 25px;
                            color: var(--btn-color);
                            margin-bottom:5px;
                            font-weight: 500;
                        }
                        .productos{
                            margin-top: 20px;
                            display: grid;

                            .headersProd{
                                display: inline-table;
                            }
                            
                            .itemproducto{
                                display: inline-table;
                                .cell{  
                                    width: 20%;
                                    text-align: center;
                                    .imagen{
                                        gap:20px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .totalPrecio
                    {
                       
                        display: block;
                        margin: 20px;
                        justify-content: space-between;
                        width: auto;
                        float: inherit;
                    

                        .TotalFull{
                            margin-top: 10px;
                            display: flex;
                            justify-content:inherit;
                            width: 100%;
                            gap:30px;
                        }
                        .TotalEnvio{
                            margin-top: 10px;
                            display: flex;
                            justify-content:inherit;
                            width: 100%;
                            gap:30px;

                            .letra {
                                font: normal normal normal 20px Helvetica
                            }
                        }

                    }
                   

                    .SeleccionMetodoMobile{
                        display: flex;
                        margin-bottom:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .mostrar {
                        opacity: 1;
                    }

                    .continuar{

                        .button{
                            margin: 20px;
                        }
                    }
                }
                .left{
                    width: 100%;
                    padding:0px;
                    padding-bottom:10px;
                    border-bottom:1px dotted #A7A7A7;
                    background-color: #FFFFFF;
                    color: var(--btn-color);

                    .title{display: none;}

                    .SeleccionMobile{
                        display: flex;
                        margin-bottom:10px;
                        margin-top:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .direcciones{
                        width: 100%;
                        display: grid;
                        //grid-template-columns: repeat(2, 1fr);
                        transition: opacity 0.8s ease;
                        opacity: 0;
                        
                        
                        .cardAddress{
                            margin:20px;
                            border: 1px solid rgba(90, 90, 90, 0.3);
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);

                            .iconCard{
                                margin-right: 20px;
                            }
                            .dir{
                                gap:50px;
                                .item{
                                    margin-top:5px;
    
                                    h1{
                                        font-size: 18px;
                                        font-weight: 500;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .cardAddress.selected{
                            border-left: 15px solid #4198b4;
                            background: #F2F2F2 0% 0% no-repeat padding-box;
                            box-shadow: 0px 3px 6px #00000029;
                            color:  var(--btn-color);
                            opacity: 1;
                            font-size: 15px;
                            
                        }

                        .DireccionSeleccionada{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            background-color: #FFFFFF;
                            display: grid;
                            gap:20px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);
                            
                            
                            .titleDireccion{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsDireccion{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }
                        }
                    }

                    .AddAddress{
                        margin:20px;
                            border: 2px dotted var(--btn-color);;
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;

                        .txtlink{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-bottom: 1px solid #FFFFFF;
                            }
                    }
                    .mostrar {
                        opacity: 1;
                    }
                }

            }
        }
    }
    @media only screen and (max-width: 390px) {

        .contetnDelivery{
            width: 100%;
            margin:5px;
            

            .rutaDelivery{
                justify-content: left;
                margin-bottom: 20px;
            }

            .titulo{display: none;}

            .rutaDelivery{
                .InactiveMobile{
                    display: none;
                }
            }

            .contentInfo{
                display: flex;
                flex-direction: column;
                margin: 5px;
                gap:10px;

                .right{
                    display: grid;
                    width: 100%;
                    padding:0px;
                    padding-bottom: 30px;
                    gap: 10px;

                    .FormaDePago{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                        margin-bottom: 20px;
                        transition: opacity 0.8s ease;
                        opacity: 0;

                        .btn{
                            width: 100%;
                            .Active{
                                background-color:var (--btn-color);
                            }
                        }

                        .MetodoSeleccionado{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            display: grid;
                            gap:20px;
                            width: 100%;
                            box-shadow: 5px 5px 10px rgba(90, 90, 90, 0.3);
                            
                            
                            .titleMetodo{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsMetodo{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }


                        }
                    }

                    .contentProductos
                    {
                        padding-top: 20px;

                        .TitleOrden{
                            display: flex;
                            font-size: 25px;
                            color: var(--btn-color);
                            margin-bottom:5px;
                            font-weight: 500;
                        }
                        .productos{
                            margin-top: 20px;
                            display: grid;

                            .headersProd{
                                display: inline-table;
                            }
                            
                            .itemproducto{
                                display: inline-table;
                                .cell{  
                                    width: 20%;
                                    text-align: center;
                                    .imagen{
                                        gap:20px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .totalPrecio
                    {
                        width: auto;
                        margin: 10px;

                        .TotalFull{
                            margin-top: 5px;
                            font: normal normal normal 14px Helvetica;
                            .totalprice{font: normal normal normal 16px Helvetica;}
                        }

                        .TotalEnvio{
                            margin-top: 10px;
                            display: flex;
                            
                            width: 100%;
                            gap:30px;
                            
                            .title{
                                font: normal normal normal 14px Helvetica;
                            }

                            .precio{ 
                                font: normal normal normal 16px Helvetica;
                                color: #4198B4;
                            }
                            .letra {
                                font: normal normal normal 14px Helvetica
                            }
                        }
                        
                    }

                    .SeleccionMetodoMobile{
                        display: flex;
                        margin-bottom:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .mostrar {
                        opacity: 1;
                    }

                    .continuar{
                        width: auto;

                        .button{
                            margin: 20px;
                        }
                    }
                }
                .left{
                    width: 100%;
                    padding:0px;
                    padding-bottom:10px;
                    border-bottom:1px dotted #A7A7A7;
                    background-color: #FFFFFF;
                    color: var(--btn-color);

                    .title{display: none;}

                    .SeleccionMobile{
                        display: flex;
                        margin-bottom:10px;
                        margin-top:10px;

                        .MostarDivLink{
                            text-decoration: none;
                            color: var(--btn-color);
                            //border-bottom: 1px solid var(--btn-color);
                            align-items: center;
                            display: flex;
                            font-size: 18px;
                        }
                    }

                    .direcciones{
                        width: 100%;
                        display: grid;
                        //grid-template-columns: repeat(2, 1fr);
                        transition: opacity 0.8s ease;
                        opacity: 0;
                        
                        
                        .cardAddress{
                            margin:20px;
                            border: 1px solid rgba(90, 90, 90, 0.3);
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);

                            .iconCard{
                                margin-right: 20px;
                            }
                            .dir{
                                gap:50px;
                                .item{
                                    margin-top:5px;
    
                                    h1{
                                        font-size: 18px;
                                        font-weight: 500;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .cardAddress.selected{
                            border-left: 15px solid #4198b4;
                            background: #F2F2F2 0% 0% no-repeat padding-box;
                            box-shadow: 0px 3px 6px #00000029;
                            color:  var(--btn-color);
                            opacity: 1;
                            font-size: 15px;
                            
                        }

                        .DireccionSeleccionada{
                            color: var(--btn-color);
                            margin:20px;
                            padding:20px;
                            border: 1px solid #c4c4c4;
                            background-color: #FFFFFF;
                            display: grid;
                            gap:20px;
                            box-shadow: 5px 5px 5px rgba(90, 90, 90, 0.2);
                            
                            
                            .titleDireccion{
                                font-size: 20px; 
                                font-weight: 500;
                            }
                            .ItemsDireccion{
                                .item{
                                    font-size: 16px;
                                }
                            }
                            .MostarDivLink{
                                font-size: 13px;
                                text-decoration: none;
                                color: var(--btn-color);
                                align-items: center;
                                display: flex;
                            }
                            hr{

                            }
                        }
                    }

                    .AddAddress{
                        margin:20px;
                            border: 2px dotted var(--btn-color);;
                            margin-bottom:10px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color:  var(--btn-color);
                            padding: 10px;
                            opacity: 1;
                            font-size: 15px;

                        .txtlink{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-bottom: 1px solid #FFFFFF;
                            }
                    }
                    .mostrar {
                        opacity: 1;
                    }
                }

            }
        }


    }

}

.contentModal{
    display: inline;
    .button{
        display: flex;
        justify-content: right;

        .cerrar{
            float: right;
            font-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
    .modal{
        display: flex;
        justify-content: center;
    }
}



