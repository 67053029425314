.WrapperConstruccion{
    justify-content: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 100px;
    .contentConstruccion{
        justify-content: center;
        .imgConstruccion{
            text-align: center;
            img{ 
                height: 300px;
                transition: 1s;
                }
        }
        .txtConstruccion{
            font-size: 20px;
            text-align: center;

            .LinumLogo{
                margin-top: 2%;
                img{ 
                    height: 50px;
                    transition: 1s;
                    }
            }
        }
    }
}