.contentAddDireccion
{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 24px 54px #0000000D;
    background: #F7F7F7;
    margin-bottom:50px;
    padding:30px;   
    width: 600px;

    .titulo{
        font: normal normal bold 40px/39px Helvetica;
        letter-spacing: 0px;
        color: #1D4C5A;
        text-align: center;
    }
    .form{
        
        display: flex;
        flex-direction: column;
        gap:10px;

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap:10px;
            width: 100%;
            
            .FormRow{
                display: flex;
                flex-direction: column;
                width: 100%;
                
                input, select{
                    width: 100%;
                    height: 50px;
                    font-size: 15px;
                    border: 1px solid #A7A7A7;
                }

             
                
                label{
                    margin-bottom: 5px;
                    color: #232323;
                }
            }
        }
        .ContentBtn{
            button{
                color:white;
                display: flex;
                background-color: var(--btn-color);
                justify-content: center;
                align-items: center;
                border: 1px solid var(--btn-color);
                width: 298px;
                height: 46px;
                padding: 10px;
                cursor: pointer;
                text-decoration: none;
                font: normal normal normal 18px/22px Helvetica;
                margin-top:30px;
            }
        }

        .mejerror{
            p {color: brown;}
        }
    }
}
@media only screen and (min-width: 900px) and (max-width: 1100px){
}
@media only screen and (min-width: 320px) and (max-width: 900px) {
    
    .contentAddDireccion
    {
        width: 100%;
        padding:20px;
        
    .titulo{
        font: normal normal bold 28px Helvetica;
        }

        .form{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:10px;
            width: 100%;
            
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-content: space-between;
                gap:20px;
                width: 100%;
                
                .FormRow{
                    display: grid;
                    //flex-direction: column;
                    width: 100%;
                    
                    input, select{
                        width: calc(100% - 10px);
                        height: 50px;
                        font-size: 15px;
                        border: 1px solid #A7A7A7;
                    }
                   

                    label{
                        margin-bottom: 5px;
                        color: #232323;
                    }
                }
            }

            .rowUno{
                display: flex;
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                button{
                    color:white;
                    display: flex;
                    background-color: var(--btn-color);
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--btn-color);
                    width: 298px;
                    height: 46px;
                    padding: 10px;
                    cursor: pointer;
                    text-decoration: none;
                    font: normal normal normal 18px/22px Helvetica;
                    margin-top:30px;
                }
            }
    
            .mejerror{
                p {color: brown;}
            }
        }

    }   
    
}
@media only screen and (max-width: 320px) {
    .contentAddDireccion
    {
        width: 100%;
        padding:20px;
        
    .titulo{
        font: normal normal bold 28px Helvetica;
        }

        .form{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:10px;
            width: 100%;
            
            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-content: space-between;
                gap:20px;
                width: 100%;
                
                .FormRow{
                    display: grid;
                    //flex-direction: column;
                    width: 100%;
                    
                    input, select{
                        width: calc(100% - 10px);
                        height: 50px;
                        font-size: 15px;
                        border: 1px solid #A7A7A7;
                    }
                   

                    label{
                        margin-bottom: 5px;
                        color: #232323;
                    }
                }
            }

            .rowUno{
                display: flex;
            }
            .ContentBtn{
                display: flex;
                justify-content: center;
                button{
                    color:white;
                    display: flex;
                    background-color: var(--btn-color);
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--btn-color);
                    width: 298px;
                    height: 46px;
                    padding: 10px;
                    cursor: pointer;
                    text-decoration: none;
                    font: normal normal normal 18px/22px Helvetica;
                    margin-top:30px;
                }
            }
    
            .mejerror{
                p {color: brown;}
            }
        }

    }   
}