.WrapperResumen{
    justify-content: center;
    display: flex;
    margin-bottom:80px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    opacity: 1;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    .contentResumen{
        width: 80%; 
        max-width: 1620px;
        height: 100%;
        margin: 20px;
        box-shadow: 10px 24px 54px #0000000D;

        .rutaResumen{
            font: normal normal medium 18px/21px Raleway;
            letter-spacing: 0px;
            color: #CDCFD1;
            opacity: 1;
            display: flex;
            
            img{
                width: 54px;
                height: 47px;
            }
            .t2
            {
                font: normal normal medium 18px/21px Raleway;
                letter-spacing: 0px;
                color: #4198B4;
                opacity: 1;
            }
        }
        .rutaPago{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font: normal normal normal 15px/25px Helvetica;
            letter-spacing: 0px;
            color: #787a7a;
            align-items: center;

            .Active {
                color: #4198B4;
            }

            .itemRutaPago{
                display: flex;
                align-items: center;
                gap:10px;
                
                .circle {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .circle span {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }

                .circleActive {
                    background-color: #1D4C5A;
                    color: white;
                }


            }
        }
        .Resumen{
            display: grid;
            width: 70%;
            gap:50px;
            justify-content: center;
            margin: auto;
            background-color:aliceblue;
            padding: 50px;
            
            .ItemResumen{

                .title{
                    font-size: 20px;
                }

                .info{

                }

                .contentProductos
                {
                    width: 100%;
                    .TitleOrden{display: none;}
                    .productos-movil
                        {
                            display: none;
                        }

                    .productos{
                        width: 100%;
                        display: table;
                        border-collapse: collapse;
                        align-items: center;

                        .headersProd{
                            display: table-row;
                            font: normal normal normal 18px/21px Raleway;
                            letter-spacing: 0px;
                            color: #1D4C5A;
                            

                            h1{
                                font-size: 14px;
                            }
                        }
                        .headersProd > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:40px;
                            
                        }
                        
                        .itemproducto{
                            display: table-row;
                            

                            .cell{  
                                vertical-align: middle;

                                .imagen{
                                    display: flex;
                                    align-items: center;
                                    gap:50px;

                                    img{
                                        width: 50px;
                                        height: 50px;
                                    }
                                    .titleProd{
                                        font: normal normal normal 18px Helvetica;
                                        letter-spacing: 0px;
                                        color: #232323;
                                    }
                                }
                                    
                                .cantidadContent{
                                    display: flex;
                                    align-items: center;
                                    gap:20px;

                                    .btn{
                                        border: none;
                                        color: white;
                                        text-decoration: none;
                                        cursor: pointer;      
                                    }

                                    .LabelCantidad{
                                        display: none;
                                    }

                                    .contentBtn{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: var(--btn-color);
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    .contentBtnDes{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: #dcdcdc;
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    h1{
                                        font: normal normal medium 18px/21px Raleway;
                                    }
                                    .contador{
                                        text-align: left;
                                        font: normal normal bold 17px/29px Helvetica;
                                        letter-spacing: 0px;
                                        color: #4198B4;
                                        opacity: 1;
                                    }
                                }
                                
                                .precio{
                                    color: #A7A7A7;
                                    display: flex;
                                    .Oferta{
                                        color: red; 
                                        font-size: 11px;
                                        margin:0;
                                    }
                                }
                                .total{
                                    color: #4198B4;

                                }
                                .delete{
                                    cursor: pointer;

                                }
                            }
                        }

                        .itemproducto > div {
                            display: table-cell;
                            padding: 5px;
                            padding-bottom:20px;
                        }
                    }
                    
                }
                .totalPrecio
                {
                    width: 300px;
                    float: right;
                    margin: 0;
                    display: grid;
                    gap:20px;
                    margin-top:20px;
                    font: normal normal normal 16px/20px Helvetica;

                    .TotalEnvio{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        font: normal normal normal 14px/20px Helvetica;
                        }
                        .precio{ 
                            
                            color: #4198B4;
                        }
                        .bold{
                            font-weight: bold;
                        }
                        .letra {
                            
                        }
                    }
                    
                }
            }
            .TerminosYCondiciones{
                .top{
                    display: flex;
                    grid-template-columns: repeat(1, 1fr);
                    grid-column-gap:10px;
                    justify-content: space-between;
                .contentToggle{

                    display: flex;
                    align-items: center;
                    gap:10px;

                    .toggle-container {
                        position: relative;
                        display: inline-block;
                    }
                    
                    .toggle-button {
                        position: relative;
                        appearance: none;
                        background-color: #ccc;
                        border: none;
                        width: 50px;
                        height: 24px;
                        border-radius: 24px;
                        outline: none;
                        cursor: pointer;
                        transition: background-color 0.3s ease;
                    }
                    
                    .slider {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: white;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                        transition: transform 0.3s ease;
                    }
                    
                    .toggle-button.toggled-on {
                        background-color: #3fdabf; /* Color de fondo cuando está encendido */
                    }
                    
                    .toggle-button.toggled-off {
                        background-color: #ccc; /* Color de fondo cuando está apagado */
                    }
                    
                    .toggle-button.toggled-on .slider {
                        transform: translateX(26px); /* Desplaza el slider a la derecha cuando está encendido */
                    }

                    .txtToggle{

                    }
                }

                .btns{
                    display: flex;
                    .button{
                        text-transform: uppercase;
                        color:white;
                        display: flex;
                        background-color: var(--btn-color);
                        justify-content: center;
                        align-items: center;
                        border: 1px solid var(--btn-color);
                        width: 150px;
                        height: 30px;
                        padding: 10px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 14px;
                        margin-top:30px;
                        float:right;
                        margin-right: 5px;
                    }
                    
                    .inactive{
                        background-color: #787a7a;
                    }

                    .continuar{

                    }
                    .atras{
                        .button{
                        background-color: #A7A7A7;
                        border: 1px solid #A7A7A7;
                        }
                    }

                }
                }
                .bottom{
                    margin-top: 10px;
                    .txtFactura{
                        font-size: 14px;
                        width: 50%;
                    }
                }
            }
        }
    
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 390px) and (max-width: 900px) {
        .contentResumen{
            width: 100%;
            margin: 5px;

            .rutaPago{
                justify-content: left;
                margin-bottom: 20px;
                .InactiveMobile{
                    display: none;
                }
            }

            .Resumen{
                display: grid;
                    width: 100%;
                    padding:0px;
                    padding-bottom: 30px;

                .ItemResumen{
                    .totalPrecio{
                        display: block;
                        margin: 20px;
                        justify-content: space-between;
                        width: auto;
                        float: inherit;

                        .TotalEnvio
                        {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom:10px;

                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        font: normal normal normal 14px/20px Helvetica;
                        }
                        .precio{ 
                            
                            color: #4198B4;
                        }
                        .bold{
                            font-weight: bold;
                        }
                        .letra {
                            
                        }
                    }
                    }

                    .contentProductos
                    {

                        width: auto;
                        margin:10px;

                        .TitleOrden{
                            
                            display: flex;
                            font-size: 25px;
                            color: var(--btn-color);
                            margin-bottom:5px;
                            font-weight: 500;
                        }
                        .productos{
                            margin-top: 20px;
                            display: grid;

                            .headersProd{
                                display: inline-table;
                            }
                            
                            .itemproducto{
                                display: inline-table;
                                .cell{  
                                    width: 20%;
                                    text-align: center;
                                    .imagen{
                                        gap:20px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }


                }


                .TerminosYCondiciones{
                    .top{
                        display: grid;
    
                        .btns{
                            justify-content: space-between;
                        }
                    }
                    .bottom{
                        margin-top: 10px;
                        .txtFactura{
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
            
        }
    }
    @media only screen and (max-width: 390px){
        .contentResumen{
            width: 100%;
            margin: 0px;

            .rutaPago{
                justify-content: left;
                margin-bottom: 20px;
                .InactiveMobile{
                    display: none;
                }
            }

            .Resumen{
                display: grid;
                    width: 90%;
                    padding:0px;
                    padding-bottom: 30px;
                    

                .ItemResumen{
                    display: grid;
                    .totalPrecio{
                        display: block;
                        margin: 20px;
                        justify-content: space-between;
                        width: auto;
                        float: inherit;

                        .TotalEnvio
                        {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom:10px;

                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        font: normal normal normal 14px/20px Helvetica;
                        }
                        .precio{ 
                            
                            color: #4198B4;
                        }
                        .bold{
                            font-weight: bold;
                        }
                        .letra {
                            
                        }
                    }
                    }

                    .contentProductos
                    {

                        width: auto;
                        margin:10px;

                        .TitleOrden{
                            
                            display: flex;
                            font-size: 25px;
                            color: var(--btn-color);
                            margin-bottom:5px;
                            font-weight: 500;
                        }
                        .productos{
                            margin-top: 20px;
                            display: grid;

                            .headersProd{
                                //display: flex;
                            }
                            
                            .itemproducto{
                                //display: flex;
                                .cell{  
                                    width: 20%;
                                    text-align: center;
                                    .imagen{
                                        gap:20px;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }


                }


                .TerminosYCondiciones{
                    .top{
                        display: grid;
    
                        .btns{
                            display: ruby;
                            justify-content: space-between;
                        }
                    }
                    .bottom{
                        margin-top: 10px;
                        .txtFactura{
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
            
        }
    }
}

.modal-PagoErr{
    height: 100%;
    border: '2px solid rgb(210, 67, 67)';
    display: grid;
    color:#545454;
    padding : 0px ;
    align-items: center;
    
.HeaderPagoErr{
    display: flex;
    padding :0px 20px 0px 20px;
    font-weight: 500;
}
.mejerror{
    display: flex;
    align-items: center;
    gap:10px;
    font: normal normal 500 16px/18px Helvetica;
    padding :0px 20px 0px 20px;
}
.btn{
    padding :0px 20px 0px 20px;
    border-top: 1px solid #383838;
    height: 100%;
    
    
    .txt{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .btnclose {
            height: 100%;
            width: 100%;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
    
}
}

.contentModal{
display: inline;
.button{
    display: flex;
    justify-content: right;

    .cerrar{
        float: right;
        font-size: 20px;
        border: none;
        cursor: pointer;
    }
}
.modal{
    display: flex;
    justify-content: center;
}
}