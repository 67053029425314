.contentRecuperarPwd{
    width: 521px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 10px 24px 54px #0000000D;
    background: #F7F7F7;
    margin-bottom:50px;
    padding: 30px;
    margin-top: 50px;
    
    .infoRecuperarPwd{
        display: grid;
        gap:10px;
        margin-bottom: 20px;
        .title{
            font: normal normal bold 40px/39px Helvetica;
            letter-spacing: 0px;
            color: #1D4C5A;
            text-align: center;

        }
        .txt{   
            letter-spacing: 0px;
            color: #4c4c4c;
            text-align: center;
        }
    }

    .SendEmailTxt
    {
        display: grid;
        gap:20px;
        .button2{
            display: flex;
            justify-content: right;
            .button{
                background-color: var(--btn-color);
                padding: 10px;
                display: flex;
                justify-content: center;
                color: #FFFFFF;   
                cursor: pointer;
            }
        }
    }
    .ConfirmPwd
    {
        display: grid;
        gap:20px;
        .button2{
            display: flex;
            justify-content: center;
            .button{
                background-color: var(--btn-color);
                padding: 10px;
                display: flex;
                justify-content: center;
                color: #FFFFFF;   
                cursor: pointer;
            }
        }
    }

    .FormCorreo{

        form{
            display: grid;
            gap:10px;
            
            .label{
                font: normal normal normal 16px/20px Helvetica;
                color: #4c4c4c;
            }
            .input{
                justify-content: center;
                padding: 10px;
            }
            .ContentButton
            {
                display: flex;
                justify-content: right;

                button{
                    background-color: var(--btn-color);
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    color: #FFFFFF;   
                    cursor: pointer;
                    border: none;
                }
            }
            .captcha{
                .txtCaptcha{
                    color:rgb(189, 31, 3);
                    font-size: 14px;            
                }
            }
        }
    }
    .txtError{
        color:rgb(189, 31, 3);
        font-size: 14px;
    }

}
