.wrapperContacto
{
    justify-content: center;
    opacity: 1;
    margin-top: 20px;
    display: flex;
    background: #F7F7F7;

    .contenedorContacto
    { 
        justify-content: center;
        width: 95%;
        max-width: 1620px;
        margin-bottom: 30px;
        
        .ContentTitulo
        {
            display: flex;
            justify-content: center;
            text-align: center;
            
            .tituloContacto{
                text-align: center;
                justify-content: center;
                margin-bottom: 20px;
                width: 500px;
                margin-bottom: 50px;

                h1{
                    font: normal normal bold 40px/48px Helvetica;
                    letter-spacing: 0.5px;
                    color: #000000;
                    opacity: 1;
                    margin-bottom: 10px;
                }

                span{
                    font: normal normal normal 15px/25px Raleway;
                    letter-spacing: 0.4px;
                    color: #000000;
                    opacity: 1;
            }
            }
        }

        .ContentForm{
            display: flex;
            gap:70px;
            background: #FFFFFF;
            box-shadow: 10px 24px 54px #0000000D;
            .FormLeft
            {
                width: 40%;
                background-color: var(--btn-color);
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                text-align: center;
                gap: 1.5rem;
                padding-top: 50px ;

                h1{
                    text-align: initial;
                    letter-spacing: 0.5px;
                    font: normal normal bold 24px/29px Helvetica;
                    opacity: 1;
                    padding-top: 2rem;
                    padding-left: 1.5rem;
                }

                .itemContacto{
                    display: grid;
                    margin-bottom: 20px;
                    gap:10px;
                .item
                    {
                        font: normal normal 300 16px/19px Helvetica;
                        padding-left: 2rem;
                        display: flex;
                        gap: 30px;
                        align-items: center;

                        .Link{
                            color: #FFFFFF;
                            text-decoration: none;
                        }

                    }
                }
                .Title{
                    font: normal normal 500 20px/19px Helvetica;
                    
                    h1{
                        letter-spacing: 3px;
                        padding:10px;
                        text-align: center;
                    }
                }

                .item_immage {
                    filter: invert(97%) sepia(100%) saturate(0%) hue-rotate(16deg) brightness(103%) contrast(103%);
                    height: 2rem;
                }
                .img{
                    padding:5px;
                }

            }
            .FormRight{
                font: normal normal bold 16px/20px Helvetica;
                width: 100%;

                h2{
                    padding-top: 2rem;
                }
                
                .form-box label{
                    text-align: center;
                    font-size: 20px;
                    margin: 0 0 2vh 0;
                }
                
                .form-container {
                
                    align-items: center;
                    padding-right: 50px;
                    font: normal normal normal 15px/18px Raleway;
                    letter-spacing: 0.57px;
                    color: #AAAAAA;

                    p {
                        color: #aa0b20;
                    }

                    .row{
                        display: flex;
                        gap:25px;

                        h2 {
                            padding-top: 0px;
                            text-align: center;
                        }

                        .itemform
                        {
                            width: 100%;
                            
                            .hidden{
                                display: none;
                            }

                            textarea {
                                resize: none;
                              }
                        }
                    
                    }
                    .rowButton
                    {
                        text-align: right;
                        padding-bottom: 30px;
                    }
                
                    .form-title {
                        font: normal normal bold 20px/25px Helvetica;
                        letter-spacing: 0.5px;
                        color: #000000;
                        opacity: 1;
                    }
                    
                    .form-input {
                        width: 100%;
                        padding: 0.5rem;
                        margin-right: 10px;
                        margin-bottom: 1rem;
                        border: 1px solid #C5C5C5;
                        opacity: 1;
                    }

                    .invalid{
                        border-color: #aa0b20;
                        background-color: #f5dbdb;
                    }
                
                    .form-textarea {
                        width: 100%;
                        padding: 0.5rem;
                        margin-bottom: 1rem;
                        border: 1px solid #C5C5C5;
                        opacity: 1;
                        height: 10rem;
                    }
                
                    .form-submit-button {
                        background-color: var(--btn-color);
                        color: #fff;
                        padding: 0.75rem 2rem;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        text-align: left;

                        &:hover{
                            background-color: var(--btn-color-hover);
                        }
                    }

                    .captcha{
                        margin-top:20px;
                        .txtCaptcha{
                            color:rgb(189, 31, 3);
                            font-size: 14px;            
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .contenedorContacto
        { 

            .ContentForm{
                display: flex;
                flex-direction: column;
                justify-content: center;
                

                .FormLeft{
                    order:2; 
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    display: grid;
                    text-align: center;
                    padding:0px;

                    .itemContacto{
                        h1{padding:0;}
                        .item{padding:0;}
                    }
                    .Title{
                        font: normal normal bold 25px Helvetica;
                    }
                }
                .FormRight{
                    display: grid;
                    justify-content: center;
                    grid-template-columns: 1fr;
                    order:1;
                    width: auto;
                    

                    .form-container {
                        width: auto;
                        margin-left: 30px;
                        font: normal normal normal 13px/18px Raleway;
                        .row{
                            width: 100%;
                            
                        }

                        .itemform
                        {
                            width: auto;
                        }
                        
                    }
                }
            }

            .ContentTitulo
            {
                .tituloContacto{
                    h1{
                        font: normal normal bold 30px Helvetica;
                    }

                    span{
                        font: normal normal normal 13px Raleway;
                    }
                }
            }
        }

        
    }
}

.modal-Contacto{
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    font: normal normal 500 19px/29px Helvetica;
    color: var(--btn-color);
}

