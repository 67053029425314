.WrapperPago{
    justify-content: center;
    display: flex;
    margin-top: 50px;
    margin-bottom: 100px;

    .contentPago{
        justify-content: center;

        .TituloPagoOK{
            justify-content: center;
            color: var(--btn-color);
            font-size: 50px;
            .icon{
                justify-content: center;
                display: flex;
                .iconCheck{
                        font-size: 200px;
                    }
            }
        }
        .TituloPagoERR{
            justify-content: center;
            color: brown;
            font-size: 50px;
            .icon{
                justify-content: center;
                display: flex;
                .iconCancel{
                        font-size: 200px;
                    }
            }

        }

        .link
        {
            justify-content: center;
            display: flex;
            margin-top: 10px;
            .terminar{
                font-size: 20px;
                text-decoration: none;
                color: var(--btn-color);
                cursor: pointer;
            }
        }
    }

}