.home
{
    background-color: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media only screen and (min-width: 320px) and (max-width: 900px) {
    .home{
    padding-top : 15px;
    }
}






