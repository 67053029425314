.WrapperOrden{
    justify-content: center;
    opacity: 1;
    display: flex;
    margin-bottom:80px;
    background: #F7F7F7 0% 0% no-repeat padding-box;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    .contentOrden
    {
        width: 90%; 
        max-width: 1620px;
        height: 100%;

        .rutaOrden{
            font: normal normal medium 18px/21px Raleway;
            letter-spacing: 0px;
            color: #CDCFD1;
            opacity: 1;
            display: flex;
            
            img{
                width: 54px;
                height: 47px;
            }
            .t2
            {
                font: normal normal medium 18px/21px Raleway;
                letter-spacing: 0px;
                color: #4198B4;
                opacity: 1;
            }
        }
        .titulo{
            font: normal normal bold 38px/46px Helvetica;
            letter-spacing: 0px;
            color: #232323;
        }
        .rutaPago{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font: normal normal normal 18px/25px Helvetica;
            letter-spacing: 0px;
            color: #787a7a;
            align-items: center;

            .Active {
                color: #4198B4;
                
            }

            .itemRutaPago{
                display: flex;
                align-items: center;
                gap:10px;
                
                .circle {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 2px solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .circleActive {
                    background-color: #1D4C5A;
                    color: white;
                }
                .circle span {
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }


            }
        }
        
        .contentProductos
        {
            width: 100%;
            box-shadow: 10px 24px 54px #0000000D;
            padding: 10px 10px 0px 10px;

            .productos-movil
                {
                    display: none;
                }

            .productos{
                width: 100%;
                display: table;
                border-collapse: collapse;
                align-items: center;
                margin-bottom: 50px;

                .headersProd{
                    display: table-row;
                    font: normal normal normal 18px/21px Raleway;
                    letter-spacing: 0px;
                    color: #1D4C5A;

                    h1{
                        font-size: 14px;
                    }
                }
                .headersProd > div {
                    display: table-cell;
                    padding: 5px;
                    padding-bottom:40px;
                }
                
                .itemproducto{
                    display: table-row;

                    .cell{  
                        vertical-align: middle;

                        .imagen{
                            display: flex;
                            align-items: center;
                            gap:50px;

                            img{
                                width: 100px;
                                height: 100px;
                            }
                            .titleProd{
                                font: normal normal normal 20px/23px Helvetica;
                                letter-spacing: 0px;
                                color: #232323;
                            }
                        }
                            
                        .cantidadContent{
                            display: flex;
                            align-items: center;
                            gap:20px;

                            .btn{
                                border: none;
                                color: white;
                                text-decoration: none;
                                cursor: pointer;      
                            }

                            .LabelCantidad{
                                display: none;
                            }

                            .contentBtn{
                                width: 30px;
                                height: 30px;
                                display: flex;
                                background-color: var(--btn-color);
                                justify-content: center;
                                align-items: center;
                                
                            }
                            .contentBtnDes{
                                width: 30px;
                                height: 30px;
                                display: flex;
                                background-color: #dcdcdc;
                                justify-content: center;
                                align-items: center;
                                
                            }
                            h1{
                                font: normal normal medium 18px/21px Raleway;
                            }
                            .contador{
                                text-align: left;
                                font: normal normal bold 17px/29px Helvetica;
                                letter-spacing: 0px;
                                color: #4198B4;
                                opacity: 1;
                            }
                        }
                        
                        .precio{
                            color: #A7A7A7;
                            display: flex;
                            .Oferta{
                                color: red; 
                                font-size: 11px;
                                margin:0;
                            }
                        }
                        .total{
                            color: #4198B4;

                        }
                        .delete{
                            cursor: pointer;

                        }
                    }
                }

                .itemproducto > div {
                    display: table-cell;
                    padding: 5px;
                    padding-bottom:20px;
                }
            }
            .productTotal
            {
            display: flex;
            //justify-content: space-between;
            justify-content: right;
            align-items: center;

                .codeDesc{
                    //display: flex;
                    display: none;
                    height: 40px;

                    .input{
                        width: 150px;
                        font: normal normal normal 18px/24px Raleway;
                        letter-spacing: 0px;
                        color: #CDCFD1;
                        opacity: 0.92;
                    }
                    .btnAplicar{
                        color:white;
                        display: flex;
                        background-color: var(--btn-color);
                        justify-content: center;
                        align-items: center;
                        border: none;
                        width: 100%;
                        padding: 10px;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 14px;
                    }
                }
                .total
                {
                    .EstadoByCP{
                        .EstadoMunicipio{font-size: 14px;
                            margin:5px 0px 0px 0px;
                        color:#787a7a}
                    }

                    .CpEnvio{
                            display: flex;
                            .input-form{
                                width: 100%;
                                height: 40px;
                                border: 1px solid #CDCFD1;
                                font: normal normal normal 18px/24px Raleway;
                                color: #6d6d6e;
                            }

                            .button-form{
                                border:none;
                                background-color: var(--btn-color);
                                color: white;
                                cursor: pointer;
                            }
                    }


                    .TextEnvio{
                        .textEnvio{
                            font: normal normal normal 15px/43px Helvetica;
                            color: #747373;
                        }

                        .Invalid{color: red;}

                        .title{
                            color: #232323;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                            font: normal normal normal 16px/43px Helvetica;
                        }

                    }

                    .subTotal{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font: normal normal normal 16px/43px Helvetica;
                        }
                        .precio{ 
                            font: normal normal normal 23px/43px Helvetica;
                            color: #4198B4;
                        }
                    }

                    .TotalEnvio{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .title{
                        color: #232323;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font: normal normal normal 16px/43px Helvetica;
                        }
                        .precio{ 
                            font: normal normal normal 23px/43px Helvetica;
                            color: #4198B4;
                        }
                    }

                    .TotalFull{
                        float:right;
                        text-align: right;
                        .totalName{
                            color: #232323;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                            font-weight: bold;
                            }
                            .totalprice{
                                font: normal normal normal 36px/43px Helvetica;
                                letter-spacing: 0px;
                                color: #4198B4;
                                margin: 0;
                            }
                    }

                    
                }
            }
            .Checkout{
                margin-top: 20px;
                display: flex;
                gap:10px;
                justify-content: right;
                margin-bottom: 90px;
                padding-bottom:90px;
                padding-right: 5px;
                text-decoration: none;

                .Link{
                    text-decoration:none;
                    color:inherit;
                }


                .btnContinuar{
                    color:var(--btn-color);
                    display: flex;
                    background-color: white;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--btn-color);
                    width: auto;
                    padding: 10px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 14px;
                }

                .btnChekout{
                    color:white;
                    display: flex;
                    background-color: var(--btn-color);
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--btn-color);
                    width: auto;
                    padding: 10px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 14px;
                }

            }   
        }
        
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
    }
    @media only screen and (min-width: 320px) and (max-width: 900px) {
        .contentOrden{
            width: 100%; 
            margin:5px;

            .rutaPago{
                .Inactive{
                    display: none;
                }
            }
            
            .contentProductos
            {
                width: 100%;
                padding:0;

                .productos{
                    display: none;
                }
    
                .productos-movil
                {
                    width: 100%;
                    display: inline;
                    justify-content:center;

                    .ItemsMoviles
                    {
                        display: grid;
                        
                        grid-template-columns: repeat(2, 1fr);
                        margin:10px;
                        gap:5px;
                        padding:5px;
                        border: 1px solid #A7A7A7;
                        opacity: 1;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 3px 6px 6px #00000029;
                        justify-content: center;

                        .itemImagen{
                            display: flex;
                            width: 100%;
                            margin-right: 5px;

                                .imagen{
                                    display: flex;
        
                                    img{
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                        }

                        .itemproducto{
                            width: 100%;
                            justify-content: center;
                            margin-top: 10px;
                           
                            .cell{    
                                display: flex;
                                font-size: 15px;
                                justify-content: space-between;
                                

                                .label{
                                    margin-right:0px;
                                }

                                .titleProd{
                                    font: normal normal normal 18px/23px Helvetica;
                                    letter-spacing: 0px;
                                    color: var(--btn-color);
                                }
                                    
                                .cantidadContent{
                                    display: flex;
                                    align-items: center;
                                    gap:15px;
                                    padding:1px;
                                    margin-bottom: 10px;
        
                                    .btn{
                                        border: none;
                                        color: white;
                                        text-decoration: none;
                                        cursor: pointer;      
                                    }
        
                                    .LabelCantidad{
                                        display: flex;
                                    }
        
                                    .contentBtn{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: var(--btn-color);
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    .contentBtnDes{
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        background-color: #dcdcdc;
                                        justify-content: center;
                                        align-items: center;
                                        
                                    }
                                    h1{
                                        font: normal normal medium 18px/21px Raleway;
                                    }
                                    .contador{
                                        text-align: left;
                                        font: normal normal bold 17px/29px Helvetica;
                                        letter-spacing: 0px;
                                        color: #4198B4;
                                        opacity: 1;
                                    }
                                }
                                
                                .precio{
                                    color: #A7A7A7;
                                    display: flex;
                                    .Oferta{
                                        color: red; 
                                        font-size: 11px;
                                        margin:0;
                                    }
                                }
                                .total{
                                    color: #4198B4;
                                    margin-right: 50px;
                                    display: flex;
                                    justify-content: space-between;
        
                                }
                                .delete{
                                    cursor: pointer;
                                }
                            }
                        }
        

                    }
                }

                .productTotal{
                    display: inline;
                    

                    .codeDesc{margin: 10px;}
                    .total{margin: 10px;}
                }

                .Checkout{
                    
                    justify-content: center;
                    margin: 10px;
                    padding:0px;
    
                    .btnContinuar{
                        width: 50%;
                       
                    }
    
                    .btnChekout{
                        width: 50%;
                    }
    
                }   

                
            }
        }
    }
    @media only screen and (max-width: 320px) {
    }
}