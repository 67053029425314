.footer{

    .Link{
        text-decoration:none;
        color:inherit;
      }

.wrapper{
    margin: 30px 50px 20px 50px;
    display: flex;
    justify-content: center;
    
    
    .top{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap:40px;
        justify-content: center;
        
        .item{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap:10px;
            text-align: justify;
            font-size: 12px;
            color:gray;

            .logo{
                        
                img{ 
                height: 50px;
                transition: 1s;
                }
                
            }
        }
        h1{
            color:var(--btn-color);
            font-size: 20px;
        }

        .redes{
            color:var(--btn-color);
        }
        .contacto{
            color:var(--btn-color);
            display: flex;
            gap:10px;
            align-items: center;
        }

        .info{
            font-size: 10px;
        }

        .suscribir
        {
            display: flex;
            justify-content: space-between;

              .textfield{
                width: 50%;
                padding: 5px 12px;
                margin: 4px 2px;
              }

              .submit{
                background-color: var(--btn-color);;
                border: none;
                color: white;
                padding: 7px 12px;
                text-decoration: none;
                margin: 4px 2px;
                cursor: pointer;  
              }
        }

    }

}
    .bottom{
        background-color: #f6f7f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:var(--btn-color);;
        min-height: 100px;

        .left{
            margin-left: 100px;
        }

        .right{
            margin-right: 100px;
        }

    }




    @media only screen and (min-width: 380px) and (max-width: 900px) {
        .wrapper{
            margin: 20px;
            .top{
                display: inline;

                .item{
                    .item{display: none;}}
                .info{
                    font-size: 12px;
                }
            }
        }
        .bottom{
            display: grid;
            justify-content: center;

            .left{margin:0 auto;}
    
            .right{margin:0 auto;}
        }
    }
    @media only screen and (max-width: 380px) {
        .wrapper{
            margin: 20px;
            .top{
                display: inline;

                .item{
                    .item{display: none;}}
                .info{
                    font-size: 12px;
                }
            }
        }
        .bottom{
            display: grid;
            justify-content: center;

            .left{margin:0 auto;}
    
            .right{margin:0 auto;}
        }
    
    }

}