.wrapperSeccion4
{
    display: flex;
    justify-content: center;
    background: #ffffff;
    opacity: 1;
    padding-top : 30px;
    padding-bottom: 30px;
   

    .contenedorSn4
    { 
        
        width: 95%;
        max-width: 1620px;
        align-items: center;
        justify-content: center;

        .txt
        {
            width: 60%;

            .tituloh1{
                color: #232323;
                opacity: 1;
                margin-bottom: 10px;

                h1{
                    font: normal normal bold 35px/44px Helvetica;
                    position:relative;
                    display: inline-block;
                }
                
                h1::after{
                    content: '';
                    position: absolute;
                    width: 30%;
                    height: 3px;
                    background-color: var(--btn-color);
                    top:20px;
                    margin-left: 5px;
                } 
            }

            span{
                text-align: left;
                font: normal normal normal 18px/26px Raleway;
                letter-spacing: 0px;
                color: #232323;
                opacity: 1;
            }
        }

        .Products
        {
            gap:30px;
            display: flex;
            margin-top: 10px;

            .ImagePrincipal{

                width: 100%;

                .imgBig
                {
                    background: #CDCFD1 0% 0% no-repeat padding-box;
                    
                    height: 100%;

                    .Link{
                        text-decoration: none;
                        color: #ffffff;
                    }

                    .imgB{

                        width: 100%;
                        height: 100%;
                        background: #CDCFD1 0% 0% no-repeat padding-box;
                        display: flex;

                        .images{
                            width: 100%;
                            overflow: hidden;
                            position: relative;

                            &:hover{
                                .SecondImg{
                                    z-index: 2;
                                }
                            }

                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                position: absolute;
                            }

                            .mainImg{
                                z-index: 1;
                            }
                        }

                        .nombretelaBig
                        {

                            font: normal normal bold 20px/25px Helvetica;
                            letter-spacing: 0px;
                            color: #FFFFFF;
                            line-height: 1;
                            width: 208px;
                            z-index: 3;
                            position: absolute;
                            margin-top: 375px;
                            margin-left: 50px;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                        }
                    }
                }
            }

            .ImagesRight
            {
                width: 100%;
                

                .contentImgTop
                {
                    display: flex;
                    gap:30px;

                    .img2top{
                        width: 100%;

                        .Link{
                            text-decoration: none;
                            color: #ffffff;
                        }
                        
                        .imgtop
                        {
                            width: 100%;
                            background: #CDCFD1 0% 0% no-repeat padding-box;
                            display: flex;

                            .images{
                                width: 100%;
                                height: 300px;
                                overflow: hidden;
                                position: relative;

                                &:hover{
                                    .SecondImg{
                                        z-index: 2;
                                    }
                                }

                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    position: absolute;
                                }

                                .mainImg{
                                    z-index: 1;
                                }
                            }

                            .nombretela
                            {
                                z-index: 3;
                                font: normal normal bold 14px/17px Helvetica;
                                letter-spacing: 0px;
                                color: #FFFFFF;
                                line-height: 1;
                                width: 208px;
                                position: absolute;
                                padding-top: 180px;
                                padding-left: 50px;
                                text-shadow: 1px 1px 0 rgb(128, 128, 128);
                            }
                        }

                    }
                }

                .ContentImgBottom
                {
                    display: flex;
                    gap:30px;
                    .img2Bottom
                    {
                        
                        margin-top:30px;
                        width: 100%;

                        .Link{
                            text-decoration: none;
                            color: #ffffff;
                        }

                        .imgBtn
                        {
                            width: 100%;
                            background: #CDCFD1 0% 0% no-repeat padding-box;
                            display: flex;

                            .images{
                                width: 100%;
                                height: 300px;
                                overflow: hidden;
                                position: relative;

                                &:hover{
                                    .SecondImg{
                                        z-index: 2;
                                    }
                                }

                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    position: absolute;
                                }

                                .mainImg{
                                    z-index: 1;
                                }
                            }

                            .nombretela
                            {
                                z-index: 3;
                                font: normal normal bold 14px/17px Helvetica;
                                letter-spacing: 0px;
                                color: #FFFFFF;
                                line-height: 1;
                                width: 208px;
                                position: absolute;
                                padding-top: 180px;
                                padding-left: 50px;
                                text-shadow: 1px 1px 0 rgb(128, 128, 128);
                            }

                        }

                    }
                }
            }
        }   


    }


    @media only screen and (min-width: 380px) and (max-width: 1000px) {
        margin: 5px;
        .contenedorSn4
        { 
            
            width: 100%;
            .txt
            {
                width: 100%;
                .tituloh1{
                    h1{font: normal normal bold 22px Helvetica;}
                    h1::after{
                        width: 50px;
                        top:8px;

                    }
                }
                span{
                    
                    font: normal normal normal 15px/24px Raleway;
                
                }
            }    
            .Products
            {
                display: inline;

                .ImagePrincipal{
                    display: none;
                }

                .ImagesRight
                {
                width: 100%;
                

                .contentImgTop
                {
                    display: none;
                }
                .ContentImgBottom{
                    display: grid;
                    
                }
                }
            }
        }
    }
    @media only screen and (max-width: 380px) {
        margin: 5px;
        .contenedorSn4
        { 
            
            width: 100%;
            .txt
            {
                width: 100%;
                .tituloh1{
                    h1{font: normal normal bold 22px Helvetica;}
                    h1::after{
                        width: 50px;
                        top:8px;

                    }
                }
                span{
                    
                    font: normal normal normal 15px/24px Raleway;
                
                }
            }    
            .Products
            {
                display: inline;

                .ImagePrincipal{
                    display: none;
                }

                .ImagesRight
                {
                width: 100%;
                

                .contentImgTop
                {
                    display: none;
                }
                .ContentImgBottom{
                    display: grid;
                    
                }
                }
            }
        }
    }
}